@import './../../assets/styles/variables.scss';

.Faq {
	padding: 96px 0px;

	.faq-container{
		width: calc(50% - 18px);
	}

	.MuiAccordionSummary-root {
		    border-radius: 8px;
  			background-color: #f2f4f7;
			margin-bottom: 12px;
		}

		.MuiAccordion-root.Mui-expanded:last-child {
		    margin-bottom: 12px;
		}

		.MuiAccordionSummary-root.Mui-expanded{
			background-color: #f2f4f7;
			border: none;
			border-radius: 8px 8px 0px 0px;
			margin-bottom: 0px;
		}



		.MuiAccordion-root.Mui-expanded {
		  margin-top: 0px;
		    
		}

		.MuiAccordionSummary-expandIcon{
			color: var(--dark-blue-grey);
		}

		.MuiAccordionSummary-content {
		  	font-size: 16px;
			font-weight: bold;
			line-height: 2;
			color: var(--dark-blue-grey);
		}

		.MuiAccordionSummary-content.Mui-expanded {
		    margin: 0px;
		}

		.MuiCollapse-container{

			background-color: #060810;

			.MuiAccordionDetails-root {
			  padding:0px 16px 16px 16px;
			  background-color: #f2f4f7;
			  border: none;
			  border-radius: 0px 0px 8px 8px;

			   font-size: 12px;

				  line-height: 2;
				  color: var(--slate);
			}

		}

		.MuiPaper-elevation1{
			box-shadow: none;
		}

		.MuiAccordion-root:before{
			content: none;
			display: none;
		}

		.MuiCollapse-wrapper{
			background-color: #fff;
		}
}

@media (max-width: $lg) {
  .Faq {

  	.faq-grid{
  		flex-wrap:wrap;
  	}
  
	.faq-container {
	    width: 100%;
	}

  }
}