@import './../../assets/styles/variables.scss';

.HeaderDark {

 box-shadow: 0 1px 0 0 var(--very-light-blue);
  background-color: #000000;
		padding: 19px 0px;
.logo{  width: 200px;
}
.btn-o-w{
	 width: 180px;
  height: 50px;
}

}


@media (max-width: $sm) {
	 .HeaderDark {
	 	.logo{  width: 136px;}
		.side{
			display:none;
		}

	}
}