.EventActions {
	border-top:1px solid var(--very-light-pink);
	padding-top:20px;
	padding-bottom:36px;
	    text-align: center;

	.action-txt{
	  font-size: 14px;
	  font-weight: 300;
	  text-align: center;
	  color: var(--dark-blue-grey);
	  padding: 16px 0px;
	}

	.btn-d,.btn-w{
		width: 314px;
  		height: 60px;
	}

	.btn-w{
		  border: solid 1px var(--very-light-blue);
	}

	.dis{
		background-color: #b2b2b2 !important;
	}
	button.dis:hover{
		filter: none !important;
	  }

	.or{
		height: 1px;
		background:  var(--very-light-pink);
		position: relative;
		margin: 16px 0px;
		&::after{
			content:'or';
			font-size: 14px;
			font-weight: 300;
			text-align: center;
			color: var(--dark-blue-grey);
			width: 58px;
			background:var(--white);
			left: 50%;
			transform: translate(-50%,-50%);
			position:absolute;
			    
		}
	}
}