@import './../../../../assets/styles/variables.scss';

.EventPlan {

	.duration{
		
		padding-bottom:4px;
		padding-top:10px;
		p{   
		  font-size: 13px;
		  line-height: 1.85;
		  color: var(--slate);
		  font-weight: 300;
		}
		img{width: 12px;margin-right: 5px;}
	}

	.summary{
	  padding-right: 56px;
	  font-size: 12px;
	  line-height: 1.33;
	  color: var(--blue-grey);
	  font-weight: 300;
	  padding-bottom:8px;
	}

	.event-item{

	  font-size: 15px;
	  font-weight: 500;
	  line-height: 1.6;
	  color: var(--dark-blue-grey);
	  padding: 12px 0px;
	  cursor:pointer;
	  border-radius: 4px;

	  .location{
	  		display: none;

	  	  font-size: 13px;
		  line-height: 1.85;
		  color: var(--slate);
	  		
	  		background-image:url('./../../../../assets/icons/map-pin-outline.svg');
	  		background-repeat:no-repeat;
	  		background-size:16px 16px;
	  		background-position:0px center;
	  		padding-left:20px;
	  		margin-top:10px;
	  		font-weight: 300;

	  }

	  .ec-content{
	  	position:relative;
	  }

	  .ec-txt-content{
			min-height: 76px;
			padding-left:16px;
	  }

	  .selected-event-box{
	  	display: none;
	  }

	  .selected-event-box-hidden{
   				 	display: none !important;
   		}

	  &.selected{

			border-radius: 0px;
			background-color: rgba(0, 255, 34, 0.13);
			width: calc(100% + 40px);
		    left: -20px;
			padding-right: 0px;
		    padding-left: 20px;
    		position: relative;

		    .selected-event-box{
		    	display: block;
		    	margin-top: 10px;

  				background-color: #1cb331;
  				  font-size: 12px;
				  font-weight: 500;
				  text-align: center;
				  color: var(--white);
				  border-top-left-radius: 4px;
   				 border-bottom-left-radius: 4px;
   				  border-top-right-radius: 0px;
   				 border-bottom-right-radius: 0px;
   				 padding:4px 5px;
   				 line-height: 16px;

   
		    }

		    .summary{
				padding-right: 12px;
		    	
		    }

  		  .location{
		  	display: block;
		  }

  		  &:hover{
		  	.paid-warning{
			  	display: none;
			  }

			.ec-txt-content{
				visibility: visible;
			}

		  }

	  }

	  .paid-warning{
	  	display: none;

	  	  font-size: 13px;
		  font-weight: 500;
		  line-height: 1.85;
		  text-align: center;
		  color: var(--slate);
		  padding: 5px;

		  border-radius: 4px;
		  -webkit-backdrop-filter: blur(10px);
		  backdrop-filter: blur(10px);
		  border: solid 1px #e6e6e6;
		  background-color: rgba(241, 244, 252, 0.11);
		  margin-top: 0px;
		  background-image:url('./../../../../assets/images/warning-bg.png');
		  background-size:cover;
		  background-position:top left;
		  background-repeat:no-repeat;

		  position: absolute;
    	width: 100%;
    	height: calc(100% - 13px);
    	top: 13px;
    	left:0px;

    		p{    
    			position: absolute;
    			top: 50%;
    			transform: translateY(-50%);
    			width: 100%;
    			text-align: center;
    			text-decoration:underline;
    		}
	  }

	  &:hover{
	  	.paid-warning{
		  	display: block;
		  }
		.ec-txt-content{
			visibility: hidden;
		}

	  }
	}


}

@media all and (max-width: $sm) {

	.EventPlan {

		.event-item{

	  		&.selected{
  		    	width: calc(100% + 64px);
				padding-left: 32px;
				left:-32px;
			}
		}
	}

}