@import './../../assets/styles/variables.scss';

.RelatedArticles {
padding: 96px 0px;
background: #000;
	.sub-title{
		color: #fff;
		font-size:30px;
	}

	.mobile-more{display: none;}

  	.articles-container{
  		width:100%;
  		overflow:hidden;
  	}

	.articles{
		margin-right:-36px;
		flex-wrap:wrap;
		/*max-width: 100%;
	    overflow: hidden;
	    overflow-x: scroll;*/
	}
	.article{
		width: 356px;
		margin-right:36px;
		margin-bottom:36px;

		a{
			text-decoration: none;
		}
	}
	.pic{
			width: 356px;
	  height: 237px;
	background-color: lightgrey;
	margin-bottom: 19px;
	}
	.title{
	  font-size: 22px;
	  font-weight: bold;
	  line-height: 1.45;
	  color: var(--white);
	  margin-bottom: 10px;

	}
	.content{
		margin-bottom: 21px;

		p{
			font-size: 13px;
	  		line-height: 1.85;
	  		color: var(--white);

	  		 display: -webkit-box;
			 -webkit-line-clamp: 2;
			 -webkit-box-orient: vertical;  
			 overflow: hidden;
			 min-height: 48px;
		}
	}
	.author{
		.avatar{
				background-color: lightgrey;

			 width: 30px;
	  height: 30px;
	  margin-right:8px;
	  border-radius: 50%;
	}
		.name{
			 font-size: 15px;
	  font-weight: 500;
	  line-height: 1.6;
	  color: var(--white);
		}
	}

	.btn-wrap{
		text-align: center;
		padding-top:100px;
	}

	.btn-o-w{
		  width: 201px;
	  height: 50px;
	}

}

@media (max-width: $xl) {
	 .RelatedArticles {

		.article{
			width: calc(50% - 36px);
		}

		.pic{
			width:100%;
		}

		.btn-wrap{
			text-align: center;
			padding-top:30px;
		}

	}
}

@media (max-width: $md) {
	 .RelatedArticles {
	 	padding: 36px 0px;

		.article{
			width: calc(100%);
		}

		.pic{
			width:100%;
		}

	}
}

@media (max-width: $lg) {
	 .RelatedArticles {
	  .sub-title{
	  	font-size: 24px;
		}

		.mobile-more{
			display: block;
			text-align: center;
			button{width: 100%;}
		}

		.article{
		    display:none;
		}
		.article:nth-child(-n+1){
		    display:block;
		}

		.show-more-mobile-articles .article{display: block !important;}


	}
}


