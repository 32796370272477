@import './../../assets/styles/variables.scss';

.DateSelectPopup {
	z-index: 3;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
	.DateRangePicker_closeButton,.DayPickerNavigation_button{
		outline:none
	}
	.DateRangePickerInput_arrow{
		display:none;
	}
	.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    	background: #000;
    	border-color: #000;
	}
	.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover,
	.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover,
	.CalendarDay__selected_span {
	    background: rgba(0,0,0,.7);
	    border-color: rgba(0,0,0,.7);
	    color:#fff;
	}

		.DateInput.DateInput_1::after {
		    content: '';
		    position: absolute;
		    right: 16px;
		    top: 50%;
		    transform: translateY(-50%);
		    width: 20px;
		    height: 20px;
		    background-image:url('./../../assets/icons/down-chevron-grey.svg');
		    background-repeat:no-repeat;
		    background-position: center;
		    pointer-events: none;
		}

	.DateInput_input__focused {
    	border-bottom: 2px solid #fff;
	}

	.DateInput_input{
	  font-size: 16px;
	  font-weight: 500;
	  line-height: 1.5;
	  color: var(--dark-blue-grey);
	  padding-left:20px;
	  height:64px;
	  border-radius: 8px;
	 &::placeholder {
		  	  color: var(--dark-blue-grey);
		}
	}
	
	.DateRangePicker_picker {
	    z-index: 3;
	}

	.DateRangePickerInput__withBorder {
	    border-radius: 8px;
	    border: solid 1px var(--light-blue-grey);
	}
	.DateInput{
		border-radius: 8px;
	}

	    .ov{
    	    position:fixed;
	    left:0;
	    right: 0;
	    bottom:0;
	    top:0;
	    background-color:rgba(0,0,0,.75);
    }


		.close-btn{
			display: block;
			position:fixed;
			top: 10px;
			background-image:url('./../../assets/icons/close.svg');
			right: 10px;
		    /*background-image: url(/static/media/close.28467877.svg);*/
		    width: 30px;
		    height: 30px;
		    background-repeat: no-repeat;
		    background-size: contain;
		    cursor: pointer;
		    z-index: 1;
		}

		.close2{
			display: none;
			filter: brightness(20);

		}



	.popup-content{
		  width: 750px;
		  min-height: 534px;
		  border-radius: 4px;
		  background-color: var(--white);
		  max-width:100%;
		  border-radius:4px;

		  &.nodesigner{
		  	width:492px;
		  	max-width: calc(100% - 32px);
		  		.close2{
				filter: none;
				}
		  }
		  &.showfirst{
			width: 984px;
			max-width: calc(100% - 32px);
			.close2{
				filter: none;
				}
		  }
	}



	.popup-content{
    	transform: translate(-50%, -50%);
	    left: 50% !important;
	    top: 50% !important;
	    position:fixed;
    }

	.content-wrap {
	    height: 100%;
	}

	.av-container{
  		width:100%;
  		overflow:hidden;
  		padding-top: 33px !important;

  				.MuiInputLabel-outlined.MuiInputLabel-marginDense {
			    transform: translate(14px, 14px) scale(1);
			     font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--dark-blue-grey);
			}
		.MuiInputLabel-outlined.MuiInputLabel-shrink {
			    transform: translate(14px, -6px) scale(0.75);
			}

			.MuiOutlinedInput-root {
			    height: 50px;
			    border-radius: 8px;
				  border: solid 1px var(--light-blue-grey);
				  background-color: var(--white);
			}

  			.sc-logo {
		  width: 46px;
		  height: 46px;
		  margin-left: auto;
		  margin-right: auto;
		  margin-bottom: 10px;
		}

		.title {
		  font-size: 20px;
		  font-weight: bold;
		  line-height: 1.9;
		  text-align: center;
		  color: var(--slate);
		}

		.txt {
			  font-size: 14px;
			  line-height: 2;
			  color: var(--slate);
			  margin-bottom:14px;
			}

			.full-txt{
				font-size: 13px;
				  line-height: 2.46;
				  text-align: center;

			}
  	}

  	.avdays-overflow{
  		height: 94px;
  	}
  	.avdays-container{
  		width:100%;
  		overflow:hidden;
  		padding-top:10px;
  	}

	.avdays-wrap{
		margin-right: -10px;
		justify-content: start;

		&-d0,&-d1,&-d2,&-d3{
			justify-content: center;
		}

	}

	.avDay{
		  width: 33px;
		  height: 32px;
		  padding: 2px;
		  border-radius: 4px;
		  background-color: #def3e1;
		  position:relative;
	    text-align: center;
	    display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	   
	    margin-right: 10px;
	    margin-bottom: 10px;

	    &.avd0,&.avd1,&.avd2,&.avd3,&.avd4,&.avd5,&.avd6,&.avd7,&.avd8
	    {
	    	width: calc(25% - 10px);
	    }

		  &::before{
		  	position:absolute;
		  	top:2px;
		  	left:2px;
		  	content: '';
		  	pointer-events:none;
		  	 width: 5px;
			 height: 5px;
			 border-radius:50%;
			 background-color: var(--green);
		  }

		  p{
		  	font-size: 10px;
    		line-height: 11px;
    		color: var(--slate);
		  }
		  p.dnum{
		  	font-size: 12px;
    		line-height: 14px;
    		font-weight: bold;
		  }

		  &.selected{
		  	background-color: #1EAB31;
		  	p{color:var(--white);}
		  	&::before{
		  		background-size: contain;
			    background-position: center;
			    width: 12px;
			    height: 12px;
			    border: 2px solid #fff;
			    top: -5px;
			    left: -5px;
			    background-repeat:no-repeat;
			    background-image:url("./../../assets/icons/success.svg");

		  	}
		  }

		  &.not{
		  		background-color: #fff4f4;
		  		p{color: #898787;}
		  		&::before{background-color: #ff0b0b;}

		  }


	}

	.main-content{
		background-color: var(--white);
		flex-grow:1;
		padding:50px 36px;
		    min-height: 534px;
		    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: relative;
		h2{
		  font-size: 22px;
		  font-weight: bold;
		  line-height: 1.67;
		  color: var(--slate);
		  margin-bottom:6px;
		}
		.fc-wrap.wm{
			margin-bottom:24px;
		}
		
		.fc-wrap.ws {
		    margin-bottom: 12px;
		}

		.form-txt{
		  font-size: 13px;
		  line-height: 2.46;
		  color: var(--slate);
		  margin-bottom:5px;
		}
		
		.form-txt-policy{
			font-size: 13px;
			line-height: 1.50;
			color: var(--slate);
		  }
		.sml-txt{
		  font-size: 14px;
		  font-weight: 300;
		  text-align: center;
		  color: var(--dark-blue-grey);
		}

		.btn-d{
			height:64px;
			width:100%;
		}

		.email-wrap{
			.MuiInputLabel-outlined.MuiInputLabel-marginDense {
			    transform: translate(44px, 25px) scale(1);
			}
/*MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled
*/			.MuiInputLabel-outlined.MuiInputLabel-shrink {
			    transform: translate(14px, -6px) scale(0.75);
			}

			.MuiOutlinedInput-root {
			    height: 64px;
			}
		}

		
		.txt-wrap{
			.MuiInputLabel-outlined.MuiInputLabel-marginDense {
			    transform: translate(15px, 25px) scale(1);
			}
/*MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled
*/			.MuiInputLabel-outlined.MuiInputLabel-shrink {
			    transform: translate(14px, -6px) scale(0.75);
			}

			.MuiOutlinedInput-root {
			    height: 64px;
			}
		}

		.browse-txt{
		  font-size: 24px;
		  font-weight: normal;
		  line-height: 1.58;
		  text-align: center;
		  color: var(--slate);
		  margin-bottom: 64px;
		   span{
		   	color: #00b700;
		   	font-weight:bold;
		   }
		   
		}
		h2.browse-title {
		   	text-align: center;
		   }
		   .btn-d.browse-btn{
		   		margin:32px 0px;
		   }
		   .decline-txt{
		   		font-size: 14px;
			  font-weight: 300;
			  text-align: center;
			  color: var(--dark-blue-grey);
			  cursor: pointer;
			  &:hover{
				  filter: brightness(0.9);
				}
		   }
	}
	.showfirst-info{
		padding:0px 0px 0px 0px;
		background:#000;
		  width: 492px;
		  flex-shrink:0;
		  border-top-right-radius: 4px;
		  border-bottom-right-radius: 4px;

		  .avatar{
		  	height:200px;
		  	background-color:lightgrey;
		  	margin-bottom:20px;
		  }
		  .features{
			padding:0px 40px 40px 40px;
			.plan-head{
				font-size: 16px;
				color: var(--white);
				padding-left: 15px;
				margin-bottom: 20px;
				font-weight: bold;
			}
			.plan-feature{
				margin-bottom: 10px;
				img{
					width: 16px;
				}
				p{
				  font-size: 13px;
				  color: var(--white);
				  padding-left:15px;
				}
				.flags{
					margin-top:3px;
				}
				.pin{
					width: 8px;
					margin-left: 4px;
				}
				.clock{
					width: 14px;
					margin-left: 1px;
				}
				.homeMarker{
					filter: invert(1);
				}
			}
		  }


	}
	.designer-info{
		padding:91px 30px 62px 30px;
		background:#000;
		  width: 258px;
		  flex-shrink:0;
		  border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

		  .avatar{
		  	width:96px;
		  	height:96px;
		  	background-color:lightgrey;
		  	border-radius:50%;
		  	margin-bottom:33px;
		  }
		  .name{
		  	  font-family: Merriweather;
			  font-size: 30px;
			  line-height: 1.39;
			  color: var(--white);
			  margin-bottom:62px;
			  overflow: hidden;
			  span{
			  	text-decoration:underline;
			  	display: block;
			  }
			  span.tr-td{
				text-decoration:none !important;
				font-size:29px;
				}
		  }
		  .logo{
		  	img{
		  		width:190px;
		  	}
		  }


	}

		  div.validation{
		  	height: 30px;
		    text-align: center;
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    p{
		    	font-size: 11px;
		    	color:#FF0B0B;
		    }
		  }

	.loading{
	   
	    animation: rotation 2s infinite linear;
	    -webkit-animation: rotation 2s infinite linear;
		width: 58px;
	}

	.loading-txt{
		  font-size: 13px;
	  line-height: 2.46;
	  text-align: center;
	  color: var(--slate);
	}


	.loading-wrap{
	    text-align: center;
	    position: absolute;
	    z-index: 999;
	    width: 100%;
	    height: 100%;
	    top: 0px;
	    left: 0px;
	    background-color:#fff;
	    .center-center{
	    	position: absolute;
		    top: 50%;
		    left: 50%;
		    -webkit-transform: translate(-50%,-50%);
		    transform: translate(-50%,-50%);
	    }
	}

	.av-container{
			.txt.st {
				text-align: center;
				margin-bottom: 0px;

				&.pick-one{margin-bottom: 3px;}
			}
		}
	}

	@keyframes rotation {
			from {
					-webkit-transform: rotate(0deg);
					 transform:rotate(0deg);
			}
			to {
					-webkit-transform: rotate(359deg);
					 transform:rotate(359deg);
			}
	}

@media (min-width: 750px) {

	.DateSelectPopup {


		.close-btn{
			display: none;
		}

		.close2{display: block !important;}

		 max-width: 100%;
	    z-index: 1;
	    position:fixed;
	    left:0;
	    right: 0;
	    bottom:0;
	    top:0;

    .popup-content{
    	transform: translate(-50%, -50%);
	    left: 50% !important;
	    top: 50% !important;
	    position:fixed;
    }


		.DateInput{
			width:209px;
		}

		div.DateInput:first-child{
			border-right: solid 1px var(--light-blue-grey);
			border-top-right-radius:0px;
			border-bottom-right-radius:0px;
		}

		.DateRangePicker_picker{
			    top: 84px !important;
		}
		.DateInput_fang{
			    top: 73px !important;
		}
/*		.DateRangePicker_picker,.DateInput_fang{
			margin-top:18px;
		}*/
	}
}

@media (max-width: 750px) {
	.DateSelectPopup {

		.avDay{
		  width: 30px;
		}

		.DateInput:first-child {
		    border-bottom: solid 1px var(--light-blue-grey);
		    border-bottom-right-radius: 0px;
		    border-bottom-left-radius: 0px;
		}

		.close-btn{
			top:0px;
			right: 0px;
		}

		.DateInput_input{
			    height: 48px;
			}
			

		.main-content .txt-wrap {
			.MuiOutlinedInput-root {
			    height: 48px;
			}
			.MuiInputLabel-outlined.MuiInputLabel-marginDense {
				    transform: translate(15px, 17px) scale(1);
			}
			.MuiInputLabel-outlined.MuiInputLabel-shrink {
			    transform: translate(14px, -6px) scale(0.75);
			}
		}

		.main-content .email-wrap {
			.MuiOutlinedInput-root {
			    height: 48px;
			}
			.MuiInputLabel-outlined.MuiInputLabel-marginDense {
				    transform: translate(44px, 17px) scale(1);
			}
			.MuiInputLabel-outlined.MuiInputLabel-shrink {
			    transform: translate(14px, -6px) scale(0.75);
			}
			
		}
		.main-content{
			padding:24px 16px 16px 16px;
		}

		.popup-content,.main-content{
			min-height: auto;
		}


		.main-content .form-txt{
			text-align: center;
		}

		.main-content .fc-wrap.wm {
		    margin-bottom: 22px;
		}

		.main-content .fc-wrap.ws {
		    margin-bottom: 12px;
		}

		.main-content .btn-d{
			height:50px;
		}
		.av-container{
			.txt.st {text-align: center;
				margin-bottom: 4px;/*avDays padding*/

				&.pick-one{margin-bottom: 4px;}
			}
		}


		height: 100%;
		br {display:none;}
		.popup-content{
			    height: auto;
			    width: calc(100% - 32px);
			    overflow: hidden;
		}

		.main-content h2 {
    		font-size: 16px;
    		text-align: center;
		}

		.form-txt{
			text-align: center;
			line-height: 1.77;
		}
		.popup-content.nodesigner{
			width: calc(100% - 32px);
		}

		.designer-info{
			display:none;
		}
		.showfirst-info{
			display:none;
		}

		.DateRangePicker,.DateRangePickerInput,.DateInput{
			width:100%;
		}
	}
}






