@import './../../assets/styles/variables.scss';

.FooterDark {
		padding: 19px 0px;
		  background-color: #1b1b1b;

.logo{  width: 200px;
}
.side{
	p{
		 font-size: 13px;
  color: var(--white);
	}
}
.shield{    width: 16px;

  margin-right: 8px;
}
.secure{ margin-right: 26px;}
.copy{}
}


@media (max-width: $sm) {
	 .FooterDark {

	 	.side,.main{
	 		display:block !important;
	 		text-align:center;
	 	}
	 	.ssl{
	 		justify-content: center;
	 		margin:17px 0px;
	 	}
	 	.secure{ margin-right: 0px;}

	}
}