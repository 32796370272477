.PlanMapBox {
  position:relative;
  margin-top: 10px;
  cursor: pointer;


/*&::before {
    background-image: linear-gradient(to top, var(--black-0), rgba(0, 0, 0, 0.73) 69%);
    content: '';
    height: 40px;
    top: -10px;
    width: 100%;
    left: 0px;
    position: absolute;
    z-index: 1;
}*/




.showmap-txtb{
  font-weight: 300;
/*color: var(--slate);*/
  color:#000;
  font-size: 14px;
  position: absolute;
  text-align: center;
  width: 100%;
  top:0;
  bottom: 0;
  left: 0;
  
  z-index: 1;
  font-size: 20px;
  font-weight: 400;
  &::after{
    content: '';
    background-image: url(/static/media/arrow.5090955e.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 32px;
    position: absolute;
    transform: rotate(180deg);
    margin-left: 10px;
    filter: brightness(0);
  }
}

.showmap-txt1{
  color:#000;
  float:right;
  display:block;
  padding:31px;
  height:auto;
 width: 100%;
 
 &:hover {

  &::before {
    content: '';
    position: absolute;
    z-index: 1; 
    top:0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color:rgba(0,0,0,.9);
  }
  color:#fff;
  filter: brightness(20);
}
}


.showmap-txt2{
  color:#000;
  position:relative;
  display:block;
  float:right;
  padding:31px;
  height:auto;
 width: 100%;
 &:hover {

  &::before {
    content: '';
    position: absolute;
    z-index: 1; 
    top:0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color:rgba(0,0,0,.9);
  }

  color:#fff;
  filter: brightness(20);
}
}

.showmap-txtd{
  &:hover {
    &::before {
      content: '';
      position: absolute;
      z-index: 1; 
      top:0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color:rgba(0,0,0,.9);
    }
  
    .showmap-txt{
      color:#fff;
      &::after{
        filter: brightness(20);
      }
    }
  
  }
}



.showmap-txt{
    font-weight: 300;
  /*color: var(--slate);*/
  color:#000;
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 100%;
    top:0;
    left: 0;
    
    z-index: 1;
    top:50%;
    font-size: 20px;
    font-weight: 400;
    transform: translateY(-50%);
    &::after{
      content: '';
      background-image: url(/static/media/arrow.5090955e.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 20px;
      height: 32px;
      position: absolute;
      transform: rotate(180deg);
      margin-left: 10px;
      filter: brightness(0);
    }
    
}


  .mapboxgl-map{
    height: 100%;
    height: 100%;
  }

  .mapboxgl-ctrl-bottom-right,.mapboxgl-ctrl-bottom-left{
    display: none;
  }

      .map-wrap{
        width: 100%;
        height: 183px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color:lightgrey;
        overflow: hidden;
        position:relative;

        &:after {
              content: '';
              position: absolute;
              background: transparent;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
          }
      }
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
