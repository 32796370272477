@import './../../../../assets/styles/variables.scss';

.PlanSummary{

	    width: 390px;
      	max-width: 100%;

      	.MuiAccordionSummary-root {
		    background-color: #13151d;
		    color: #fff;
			border-bottom:1px solid #000;
		}

		.MuiAccordion-root.Mui-expanded {
		    margin: 0px;
		    background-color: #060810;
		}

		.MuiAccordionSummary-expandIcon{
			color: var(--white);
		}

		.MuiAccordionSummary-content {
		  /*height: 24px;*/
		  font-size: 13px;
		  margin: 16px 0;
		  line-height: 1.85;
		  color: var(--white);
		      flex-wrap: wrap;

		      .cnm{width: 100%;}
		}

		.MuiCollapse-container{

			background-color: #060810;

			.MuiAccordionDetails-root {
			  padding:16px;
			  background-color: #060810;
			  font-size: 12px;
			  line-height: 1.58;
			  color: #a9a6a6;
			}

		}

		.duration{
			
			padding-bottom:7px;
			p{  color: var(--white); }
			img{width: 12px;margin-right: 5px;}
		}

		.view-btn{
			  margin-top: 8px;
			  height: 30px;
			  padding: 3px 8px 3px 8px;
			  border-radius: 4px;
			  background-color: var(--white);

			   font-size: 13px;
			  font-weight: normal;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.85;
			  letter-spacing: normal;
			  text-align: center;
			  color: var(--slate);

			  img{margin-right:8px;}
		}

		&.isFullAccess {
			.MuiAccordionSummary-root {

			    border-bottom: none;
			}

			.MuiIconButton-root{
			}

			.MuiCollapse-container .MuiAccordionDetails-root {
			    background: #13151d;
			}

			.MuiAccordionSummary-content.Mui-expanded {
			    margin: 16px 0 0px 0;
			}

			.MuiAccordionSummary-root.Mui-expanded {
			    min-height: 20px;
			    cursor: default;
			}

			.MuiAccordion-root.Mui-expanded {
			    border-bottom: 4px solid #000;
			}


			.summary-box{
				height: auto;
			}
		}

}

@media all and (max-width: $lg) {
	 .PlanSummary {
			width: 100%;
			order:3;
	}
}
