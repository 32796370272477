@import './../../assets/styles/variables.scss';

.EveryPlan {
	background: #000;
	padding: 96px 0px;

	.btn-w{
			width: 230px;
  			height: 60px;
	}

	.to-action{
		width: 230px;
		h4{
		  font-size: 30px;
		  font-weight: bold;
		  line-height: 1.33;
		  color: var(--white);
		  margin-bottom: 32px;
		}
	}

	.plan-feature{
		margin-bottom: 26px;
		img{
			width: 16px;
		}
		p{
		  font-size: 13px;
		  color: var(--white);
		  padding-left:15px;
		}
		.flags{
			margin-top:3px;
		}
		.pin{
			width: 8px;
			margin-left: 4px;
		}
		.clock{
			width: 14px;
			margin-left: 1px;
		}
	}

	.get-started-mobile{
			display:none;
			text-align:center;
	}
}


@media all and (max-width: $lg) {
	 .EveryPlan {

	 	padding: 36px 0px;

		.to-action{
			width:100%;
			text-align:center;
			.btn-w{
				display:none;
			}
		}

		.features{
			margin-left: auto;
		    margin-right: auto;
		    width: 310px;
		}

		.get-started-mobile{
			display:block;
			.btn-w{width: 100%;}
		}

	}
}