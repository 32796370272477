@import './../../../../assets/styles/variables.scss';

.EventPreview {

	h2{
		  font-size: 30px;
		  font-weight: bold;
		  line-height: 1.33;
		  color: var(--slate);
		  margin-bottom:24px;
	}

	.demo-notice{
		border-radius: 4px;
  		background-color: rgba(0, 72, 236, 0.04);
  		padding: 24px 16px 16px 32px;
  		margin-bottom: 16px;
  		font-weight: 400;

  		img{
  			/*width:32px;*/
  			height: 32px;
  			margin-top: -10px;

  			flex-shrink: 0;
		   /* min-width: 32px;*/

		   /* max-width: 32px;*/
		    display: block;
		    margin-right:21px;
  		}

  		p{
  		  font-size: 13px;
		  line-height: 2.46;
		  color: #23a5ff;
  		}

  		span{font-weight: bold;}
	}

	.event-name-is{
		 font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: var(--blue-grey);
	}

	.event-name{

		  font-size: 22px;
		  font-weight: bold;
		  line-height: 1.45;
		  color: var(--dark-blue-grey);
		  /*padding-top:30px;*/
		  padding-bottom:11px;
		  margin-bottom: 0px;
	}

	.desc{
		color: var(--slate);
		margin-bottom:48px;
		 font-size: 16px;
 		 /*font-weight: 500;*/
 		 line-height: 1.81;

	}

	.hashtag{
	  font-size: 12px;
	  color: #23a5ff;
	  margin-right: 8px;
	  margin-bottom: 16px;
	  word-break: break-word;
	  span{margin-right: 5px;}
	}
	.event-images{
		border-radius: 6px;
		/*overflow: hidden;*/
		margin-bottom: 16px;
		
		.sticky{
			box-shadow: inset 0 0 20px 20px #00000061;
			padding-left: 5px;
			border-radius: 3px;
			float: right;
			position: sticky;
			margin-top: -40px;
			margin-right: 20px;
			a{
				color: white;
			}
			img{
				filter: invert(1);
			}
		}
	}

	.event-image{
		    /*border-radius: 6px;
			position: relative;
		    overflow:hidden;*/
		    height: 480px;
			width: 100%;
			background-position: center;
    		background-size: cover;
    		background-repeat: no-repeat;
    		background-color:lightgrey;
/*			&::after{
			  padding-top: 44.6%;
			  display: block;
			  content: '';
			}*/

	}

	.event-links-wrap{
		margin-bottom: 28px;

	}

	.event-link{
	   a{
		   	font-size: 13px;
		  line-height: 1.85;
		  color: var(--slate);
		  outline: none;
		  font-weight: 400;
		  &:hover{text-decoration: none;}
		  &:visited{color: var(--slate);}
		      word-break: break-all;
	   }

	   img{margin-right:8px;}

	}

	.web-link{
		margin-right: 31px;
		img{width: 24px;}
	}
	.insta-link{
		img{width: 17px;}
	}


}

@media (max-width: $lg) {
	 .EventPreview {

	 	.event-links-wrap{
		margin-bottom: 17px;
		}

		.demo-notice{
			  	margin-bottom: 20px;
			p{
				line-height: 1.46;
			}

			br{display: none;}
		}
		.desc{
		font-size: 13px;
		  font-weight: 500;
		  line-height: 2.23;
		}

		.event-name{

			padding-top:16px;
			padding-bottom:14px;

		}

	}
}

@media (max-width: 750px) {
	.EventPreview {
	
		.event-image{
				height: 285px;
		}
	}
	
}