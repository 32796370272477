@import './../../../../assets/styles/variables.scss';

.EventDesigner {
	margin-bottom: 36px;

	.avatar{
		background-color: #1b1b1b;
		width: 100px;
		height: 100px;
		border-radius: 50%;
	}



	.info{
		padding-left: 25px;
		margin-bottom: 17px;
		overflow:hidden;
		.name{
		  font-size: 20px;
		  font-weight: 500;
/*		  line-height: 0.8;
*/		  color: var(--slate);
		}
		.occupation{
			font-weight: 400;
		  font-size: 13px;
/*		  line-height: 2.46;
*/		  color: var(--slate);
		}
		.trips{
		  font-size: 13px;
		  line-height: 2.46;
		  color: #b0b0b0;
			
			font-weight: 400;
		}
		.local{
			font-size: 13px;

			  line-height: 1.85;
			  color: var(--slate);
			  margin-bottom: 0px;
			img{height: 16px;margin-right: 3px;}
		}
		.insta{
			font-weight: 400;
		  font-size: 13px;
/*		  line-height: 1.85;
*/		  color: var(--slate);
			img{height: 12px;margin-right: 3px;}

			a{
				    color: var(--slate);
				    text-decoration:none;
				    margin-left:0px;
				        line-height: 20px;
			}
			a:last-child{
				margin-left:2px;
			}
		}

	}

	.langs-wrap {
		img{
			width: 24px;
			margin-right: 12px;
		}
	}

	.langs{
	  font-size: 13px;
	  line-height: 1.85;
	  color: var(--slate);
	  font-weight: 400;
	}

}

@media (max-width: $md) {
	 .EventDesigner {
		display: none;
	}
}