@import './../../assets/styles/variables.scss';

.Checkout {
	padding-bottom:50px;
	.MuiInputBase-input{
		height: 29px;
	}

	.coupon-container-mobile,.terms-container-mobile,.mobile-confirm-container{
		display: none;
	}
	.terms-container-mobile{
		margin-top: 34px;
	}

	.stripe-wrap{
		label{width: 100%;}
	}

	.pay-content{
		padding-top:50px;
	}

	button.btn.btn-apply:hover{
	  filter: brightness(0.9);
	}

	.btn-back{
	  width: 92px;
	  height: 50px;
	  border-radius: 4px;
	  background-color: var(--pale-grey);

	  border:none;
	  margin: 36px 0px;
	}

	.left{
		width: 540px;
		margin-right: 47px;
	}

	.right{
		 width: 356px;
	}

	.product{

		.product-details{
			align-items:center;
		}

		.product-image{
			margin-right:15px;
			 width: 64px;
		  height: 64px;
		  border-radius: 4px;
		  background-color:lightgrey;
		      background-size: cover;
		    background-repeat: no-repeat;
		    background-position: top center;
		    padding: 10px;

		  .date-wrap{
		  	background-color: rgba(255, 255, 255, 0.1);
    		border-radius: 4px;
			  line-height: 1.85;
			  text-align: center;
			  color: var(--white);
			  font-weight:500;
			  backdrop-filter: blur(14px);

			  .month{
			  		font-size: 13px;
			  		line-height: 1.85;
			  }

			  .day{
			  	  font-weight: 500;
			  	  font-size: 15px;
			  	    line-height: 1.6;

			  }
		  }
		}
		.product{
			    padding: 13px 0px;
		}
		.product-info{
				    width: 100%;
		    overflow: hidden;}
		   .product-details{
		   	width: 100%;
		   }

		   
		.product-link{
			margin-left: 15px;
			width: 32px;
			height: 64px;
			border-radius: 4px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: top center;
			padding: 10px;
			}
			

		.product-name{
		 font-size: 15px;
		  font-weight: 500;
		  line-height: 1.6;
		  color: var(--dark-blue-grey);
		}
		.product-designer{
		  font-size: 12px;
		  line-height: 1.5;
		  color: var(--slate);
		}

		.checkout-btn{
			 font-size: 13px;
		  font-weight: 500;
		  text-align: center;
		  color: var(--dark-blue-grey);
    		padding: 6px 10px;
	  		background-color: var(--pale-grey);

		  width: 117px;
  		/*	height: 32px;*/
		}
	}



		.btn-d{
			margin-top: 32px;
		  width: 100%;
		  height: 60px;
		}

		.tree-txt{
			 font-size: 13px;
			 line-height: 1.85;
			 color: var(--slate);
			 padding: 14px 0px;
			 padding-right: 15px;
		}

	h1{
		  margin-bottom: 12px;
		  font-family: Merriweather;
		  font-size: 30px;
		  font-weight: bold;
		  line-height: 1.33;
		  color: var(--slate);
	}

	.info-txt{
		font-size: 13px;
  		line-height: 2.46;
  		color: var(--slate);
  		margin-bottom:23px;
	}

	.see-more{
		  font-weight: 500;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.85;
		  letter-spacing: normal;
		  text-align: center;
		  color: var(--green);
		  margin-bottom: 24px;
	}

	.planting{
		margin-top: 24px;
		  border-radius: 4px;
  box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
  background-color: var(--white);
  margin-bottom: 50px;
  padding:3px 0px;
	}

	.form-wrap{
		margin-bottom:50px;
		.pay-w{
			margin-bottom: 15px;
			h4{
				font-size: 30px;
			  font-weight: bold;
			  line-height: 1.33;
			  color: var(--slate);
			}
			img{
				margin-left:12px;
			}
		}


		.form-inline{
			.MuiFormControl-root {
 
			    width: 50%;
			}
		}
	}

	.terms{
		  font-size: 13px;
		  line-height: 1.85;
		    color: var(--slate);

		  margin-top: 5px;

		  a,a:hover,a:visited{
				  color: var(--green);
				  margin-left: 5px;
				  margin-right: 5px;
			}

		}

	.sum-container{
		border-radius: 4px;
  		border: solid 1px var(--very-light-pink);
  		padding:24px 21px;
	}

	.plan-days{
		margin-bottom: 17px;
	}
	.pay-items{
		  border-top: solid 1px var(--very-light-pink);
		  padding: 24px 0px;
		.pay-item{
			margin-bottom: 6px;
			p{
			  font-size: 13px;
			  line-height: 1.85;
			  color: var(--slate);
			}

			p.pay-green{
			  font-size: 15px;
			  font-weight: 500;
			  line-height: 1.6;
			  text-align: right;
			  color: var(--green);
			}

		}



		.pay-total{

			.total{
				  font-size: 15px;
				  font-weight: 500;
				  line-height: 1.6;
				  color: var(--dark-blue-grey);
			}
			.pay-discount{
			  font-size: 15px;
			  font-weight: 300;
			  line-height: 1.6;
			  color: var(--pinkish-grey);
			  text-decoration: line-through;
			  margin-right: 6px;

			}
			.pay-total{
			  font-size: 15px;
			  font-weight: 500;
			  line-height: 1.6;
			  text-align: right;
			  color: var(--green);
			}
		}


	}

	.cancel-policy{
		border-top: solid 1px var(--very-light-pink);
		padding-top: 24px;

		.head{
		  font-size: 15px;
		  font-weight: 500;
		  line-height: 1.6;
		  color: var(--dark-blue-grey);
		}

		p.desc{
				font-size: 13px;
			  line-height: 1.85;
			  color: var(--slate);
			}


		.desc{

			
			a,a:hover,a:visited{
				  color: var(--green);
				  margin-left: 5px;
			}

		}
	}

	.coupon-code{

		padding: 0px 0px 16px 0px;

		&.desktop-ccode{
			padding-bottom: 36px;
			position: relative;
    		top: 8px;
    		min-height:60px;
		}

		.enter-coupon{
		  font-size: 15px;
		  font-weight: 500;
		  line-height: 1.6;
		  color: var(--dark-blue-grey);
		  background: none;
    		text-decoration: underline;
    		padding:0px;
		}

		&-txt{
			font-size: 16px !important;
		    font-weight: 500 !important;
		    color: var(--dark-blue-grey) !important;
		    padding-right: 5px;
		    text-transform: uppercase;
		}

	}

		.coupon-code-content-right{
			.coupon-code-cancel{
				padding-right: 12px;
			}
			button.btn.btn-coupon-cancel {
			    display: block !important;
			    font-size: 12px;
			    font-weight: bold;
			    font-stretch: normal;
			    font-style: normal;
			    line-height: 1.5;
			    letter-spacing: normal;
			    color: #000000 !important;
			    background: none !important;
			    height: auto !important;
			    width: auto !important;
			    text-decoration: underline;
			    text-align: right;
			    margin-right: 0px;
			    margin-left:auto;
			    padding: 0px;
			}
		}

	.coupon-head{
	  font-size: 15px;
	  font-weight: 500;
	  line-height: 1.2;
	  color: var(--dark-blue-grey);
	  margin-bottom:16px;
	}

	.coupon-cont{
		  		padding:10px;
		    padding-left: 21px !important;
		 border-radius: 4px;
  		border: solid 1px var(--very-light-pink);
  		margin-bottom:24px;
  		position:relative;
  		min-height: 66px;
  		overflow: hidden;
		p{
		  font-size: 13px;
		  line-height: 1.54;
		  color: var(--slate);
		}
		.btn{
			background-color: var(--green);
			width: 117px;
  			height: 44px;
  			color: var(--white);
		}
		.applied-text{
		  font-size: 12px;
		  line-height: 1.5;
		  color: var(--green);
		      padding-right: 12px;
		}

		&.applied{
			  border: solid 1px var(--green);
			  
			  .btn{display: none;}
		}

		&.applied::before{
			content: '';
			position:absolute;
			right: 0px;
			top:0px;
			 width: 0;
		      height: 0;
		      border-top: 30px solid var(--green);
		      border-left: 30px solid transparent;

		}

		&.applied::after{
			content: '';
		    background-image:url('./../../assets/icons/check-w.svg');
		    background-size: cover;
		    background-repeat: no-repeat;
		    width: 16px;
		      height: 16px;
		      position:absolute;
			right: 1px;
			top:2px;
		}


	}

	.mobile-confirm-container{
		width: 100%;
		  height: 65px;
		  padding: 0 0 0 10px;
		  box-shadow: 0 -11px 24px 0 var(--dark-blue-grey-13);
		  background-color: var(--white);

		  position: fixed;
		  left: 0px;
		  bottom: 0px;
		  right: 0px;

		  .back-chevron{
		  	width: 42px;
		  	height: 42px;
		  	background-repeat:no-repeat;
		  	background-size:cover;
		  	background-position:center;
		  	background-image:url('./../../assets/icons/back-chevron.svg');
		  	margin-right: 5px;
		  	cursor:pointer;
		  }

		  .total-mob{
		  	margin-left: 5px;
		  }

		  .total-txt{
		  	  font-size: 13px;
			  line-height: 1.54;
			  color: var(--slate);
		  }

		  .total-num{
		  	  font-size: 18px;
			  font-weight: 500;
			  line-height: 1.33;
			  color: var(--dark-blue-grey);
		  }

		  .confirm-btn-mobile.btnx{
			    width: 148px;
			    height: 65px;
			    background-color: var(--light-blue-grey);
			    margin: 0px;
			    border-radius: 0px;
			    font-size: 15px;
			    font-weight: 500;
			    line-height: 1.2;
			    text-align: center;
			    color: var(--white);

			    border: none;
			    outline: none!important;
			    max-width: 100%;

			    &.form-valid{
			  	background-color: var(--dark-blue-grey);
			  }
		  }

		  button.confirm-btn-mobile.btn-d:hover{
			  filter: none;
			  background-color: var(--dark-blue-grey);
			}
	}

	
}


@media (max-width: 980px) {

  /** payment page footer***/
  .Payment { .mobile-hidden-footer{display: none;}  }
  .info-txt{
  	line-height: 1.85;
    letter-spacing: -0.1px;
  	br {display:none;}
  }

  .Checkout {
  	padding-bottom:99px;

  	.pay-content {
    padding-top: 24px;
	}

  	h1 {
	    font-size: 22px;
	    line-height: 1.45;
	}

	.form-wrap {
    margin-bottom: 32px;
	}

  	.confirm-btn-desktop{ display: none; }
  	.coupon-container,.terms-container{
		display: none;
	}

  	.coupon-container-mobile,.terms-container-mobile,.mobile-confirm-container{
		display: block;
	}

  	.left,.right{
  		width:100%;
  		margin-right:0px;
  	}

  	.form-wrap .pay-w h4,.coupon-head {
	    font-size: 15px;
	    font-weight: 500;
	    line-height: 1.6;
	}

  }
}

@media (max-width: $sm) {
  .Checkout {

	.product{

		.product-details{
			align-items:flex-start;
			width:100%;
			margin-bottom:5px;
		}

		.checkout-btn{
			margin-left:79px;
		}
	}

  }
}


