@import './../../assets/styles/variables.scss';

.DesignerDetail {
	padding: 96px 0px 144px 0px;
	h2{
	  font-size: 70px;
	  font-weight: bold;
	  color: var(--slate);
	  margin-bottom: 0px;
	  margin-right: 45px;
	}

	.about-td{
	  font-size: 39px;
	  font-weight: bold;
	  line-height: 1.03;
	  color: var(--slate);
	  padding-bottom: 40px;
	  margin-bottom: 0px;
	}

	.mobile-head{
		display: none !important;
	}

	.desktop-head{
		margin-bottom: 25px;
	}
	 	

	.new-bio{
  	font-size: 20px;
  	color: var(--dark-blue-grey);
  	font-weight: 400 !important;
  	padding-top:6px;
	}

	.head{
		.sum{
			margin-bottom: 15px;
			img{
				height: 17px;
				margin:0px 7px 0px 0px;
			}
			p{
			  font-size: 20px;
			  font-weight: 500;
			  color: var(--dark-blue-grey);
			}
		}
	}

	.des-profile{
		width: 356px;
	}

	.des-content{
		margin-left: 36px;
		/*max-width: 748px;*/
		max-width: calc(100% - 401px);
	}

	.quote{
		position:relative;
		padding:33px 30px 64px 31px;
		display: none;
		p{
			font-size: 20px;
  			line-height: 1.6;
			color: var(--slate);
			  font-family: Merriweather;

		}
		.q-start,.q-end{
			width: 24px;
  			height: 22px;
  			position:absolute;
		}
		.q-start{
			left:0px;
			top:0px;
		}

		.q-end{
			/*right:0px;
			bottom: 42px;*/
			right: -10px;
		    bottom: -22px;
		    position: relative;
		}
	}

}


@media (max-width: $lg) {
	 .DesignerDetail {
	 	.quote{display: block;}
	 	h2{width: 100%;}
	 	.desktop-head{
			margin-bottom: 0px;
			h2{margin-right: 0px;}
		}
	 	
	 	padding: 36px 0px 21px 0px;

	 	.des-content{padding-top: 34px;}

	 	h2{
		  font-size: 36px;
		}

		.head{
			.sum{
				p{
				  font-size: 13px;
				}
			}
		}

		.inhabit,.new-bio{
			width: 100%;
		}


		.des-profile{
			width: 100%;
		}

		.des-content{
			margin-left: 0px;
			width: 100%;
			max-width: 100%;
		}

		.about-td{
			font-size: 24px;
 			line-height: 1;
 			padding-bottom: 24px;
		}

	}
}

@media (max-width: $md) {
	.DesignerDetail {

	 	.DesignerOther{
		}
	}
}

