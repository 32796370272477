@import './../../../../assets/styles/variables.scss';

.DesignerProfile {

iframe{
  width: 100%;
}

.photo{
	height: 356px;
  max-width:356px;
	background-color:lightgrey;
  background-position:top center;
}
.bio{
  padding-top:16px;
	p{
		  font-size: 13px;
  line-height: 2.15;
  color: var(--slate);
	}

	span{
		font-weight: bold;
	}
}
.sep{
	height: 1px;
  background:#b3b3b3;
  margin:24px 0px;
}
h5{
font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--dark-blue-grey);
}

.tag{
	  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: var(--slate);
  padding:2px 5px;
  margin-right: 10px;
  border-radius: 4px;
  border: solid 1px var(--slate);
  margin-bottom:5px;
}

.stat{
	.title{
		font-size: 13px;
  line-height: 1.85;
  color: var(--slate);
	}

	.val{
		font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  color: var(--dark-blue-grey);
	}
}
div.stat:first-child{
	margin-right:38px;
}

.spotify{
	 width: 30px;
  height: 31px;
  margin-right:6px;
}
.song{
  font-size: 13px;
  color: var(--slate);
}

.artist{
	 font-size: 15px;
  font-weight: 500;
  color: var(--dark-blue-grey);
      margin-top: -4px;

}

.cover{
	  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: #d8d8d8;
}

.fav-song-sep{
  display:none;
}


}


@media (max-width: $xl) {
  .DesignerProfile {
  
    .fav-song-sep{
      display:block;
    }

  }
}


@media (max-width: $sm) {
  .DesignerProfile {
  
    .photo{
      max-width:100%;
    }

  }
}