@import './../../assets/styles/variables.scss';

.NotfoundPlan {
	height: 100%;



	.sum-container{
		border-radius: 4px;
  		border: solid 1px var(--very-light-pink);
  		padding:24px 21px;
	}
	.avatar{
		width:100%;
		height:212px;
		background-color:white;
		margin-bottom:33px;
	}
	.btn-d {
		width: 314px;
		height: 60px;
	}
	h4{
		font-size: 30px;
	  font-weight: bold;
	  line-height: 1.33;
	  color: var(--slate);
	}
}