@import './../../../assets/styles/variables.scss';

.CheckoutForm{
  .error{
        color: #c23d4b;
        text-align: center;
  }

input.StripeElement::placeholder {
  color: var(--pinkish-grey);
}
input,
    .StripeElement {
      display: block;
      width: 100%;
      font-size: 16px;
      padding:14px;
        color: var(--dark-blue-grey);

      outline: 0;
      background: white;
       border-radius: 4px;
       border: solid 1px var(--very-light-blue);
       font-family: sans-serif !important;
       font-weight: 300;
    }

    input::placeholder {
      color: var(--pinkish-grey);
    }

    input:focus,
    .StripeElement--focus {
      border: solid 1px var(--dark-blue-grey);
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
    }

    .form-inline {
        .inline-item{
          width: calc(50% - 12px);
          display: block;
        }
    }
    div{display: block;}
    .form-item{
      margin-bottom: 26px;
    }
}