.PlanTags {

	.tag{
	  font-size: 12px;
	  font-weight: 500;
	  color: var(--white);
	  border-radius: 4px;
  	  border: solid 1px #ececec;
  	  padding: 2px 5px;
  	  /*padding-top:3px;*/
  	  margin-right: 8px;
  	  margin-bottom:8px;
  	  text-transform: uppercase;
	}



}