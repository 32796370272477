@import './../../assets/styles/variables.scss';

.PaymentSuccess {
	padding-top: 60px;


.mobile-share-btn{
	display: none;
}
	.content-wrap{ 
		max-width: 490px;
		margin:0px auto;
	}
	.success-title {
	  font-size: 22px;
	  font-weight: bold;
	  line-height: 1.45;
	  text-align: center;
	  color: var(--dark-blue-grey);
	  padding-bottom:37px;
	}

	.thanks {

  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
  color: var(--dark-blue-grey);
  margin-bottom: 10px;
}

.look {

  font-size: 13px;

  line-height: 1.85;
  text-align: center;
  color: var(--slate);
  margin-bottom: 10px;
}

	.sc-logo {
	  width: 50px;
	  height: 50px;
	  margin-left: auto;
	  margin-right: auto;
	  margin-bottom: 10px;
	}

	.unlock-box{
	  border-radius: 4px;
	  border: solid 1px var(--green-28);
	  background-color: var(--green-9);
	  padding: 14px 31px;
	  margin-bottom: 39px;

	  .unlock{
	  	margin-right: 26px;
	  }

	  	.title{
		  font-size: 14px;
		  font-weight: bold;
		  line-height: 2.29;
		  color: var(--dark-blue-grey);
		}

		.txt {
		  font-size: 12px;
		  line-height: 2;
		  color: var(--slate);
		}

	}

		.planting{
		margin-top: 15px;
		  border-radius: 4px;
	  box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
	  background-color: var(--white);
	  margin-bottom: 50px;
	  padding:0px 24px 0px 0px;

			  .plant-content{
			  	    padding-left: 15px;
			  }

			 .title {
			  font-size: 13px;
			  font-weight: 500;
			  line-height: 1.85;
			  color: var(--slate);
			}

			.tree-txt{
			 font-size: 13px;
			 line-height: 1.85;
			 color: var(--slate);
		}


	}


	.shareTitle{
	  
	  margin: 60px 0px;
	  font-size: 16px;
	  font-weight: bold;
	  line-height: 1.44;
	  text-align: center;
	  color: var(--dark-blue-grey);
	}

	.share-actions{
		button{
			height: 50px;
			width: 100%;
			margin-bottom: 24px;
		}

		.add-btn{
						font-size: 16px;
			font-weight: normal;
			  line-height: 1.5;
			  color: var(--pinkish-grey);
		}
	}

	.share-items{
		div.form-wrap:last-child{
			border:none !important;
		}
	}

	 	.mobile-logo{
	  		display: none;
	  	}

}

@media (max-width: $sm) {
	.Payment{
		.mobile-header-success{
			display: none;
		}
	}
	  .PaymentSuccess {
	  	
	  	padding-top: 50px;

	  	.mobile-logo{
	  		display: block;
	  		 text-align: center;
	  		 padding-bottom:40px;
  			 img{
  			 	width: 198px;
  			 }
	  	}

	/*  	.share-items > .ShareFriendItem:first-child > button.delBtn {
		    display: none;
		}
*/

	  	.mobile-share-btn{
	  		display: block;
	  		height: 65px;
	  		width: 100%;
	  		font-weight: 500;
	  		border-radius:0px;
	  	}
	  	.share-btn{display: none;}
	  	.planting img{
	  		width: 63px;
	  	}


	  	.unlock-box {
	  		padding: 6px 14px;
	  		padding-left: 20px;
		}
	  	.unlock-box .unlock {
		    margin-right: 15px;
		}

		.shareTitle {
		    margin-bottom: 20px;
		}

		.planting {
		    padding-right:14px;
		}


		.plant-content{
			padding-top:14px;
			padding-bottom:14px;
		}

	}
}
