@import './../../assets/styles/variables.scss';

.FooterLight {
	padding: 19px 0px;
	 box-shadow: 0 -1px 0 0 var(--very-light-blue);
.logo{  width: 200px;
}
.side{
	p{
		 font-size: 13px;
  color: var(--slate);
	}
}
.shield{    width: 16px;

  margin-right: 8px;
}
.secure{ margin-right: 26px;}
.copy{}
}

@media (max-width: $sm) {
	 .FooterLight {

	 	box-shadow: 0 -10px 24px 0 var(--dark-blue-grey-7);

		.logo{  width: 148px;}
	 	.main{
	 		display:block !important;
	 		text-align:center;
	 	}
	 	.side{
	 		justify-content:center;
	 		margin-top:26px;
	 	}
	}
}