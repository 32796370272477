@import './../../../../assets/styles/variables.scss';
@import "~video-react/styles/scss/video-react"; 

.PlanPreview {
		width: 715px;
      	max-width: 100%;
      	width: calc(100% - 390px);
      	padding-right: 33px;

      	p{
		  font-size: 13px;
		  line-height: 1.77;
		  color: var(--white);
      	}

      	h1{
		  font-size: 30px;
		  font-weight: bold;
		  line-height: 1.33;
		  color: var(--white);
		  text-align: left;
		  margin-bottom:15px;
		}
		.more{
			color: var(--white);
		}

		.plan-tags-wrap{
				margin-top: 15px;
		}

		.video-container{
			margin-bottom:32px;
		}

		.video-react .video-react-big-play-button:before, .video-react .video-react-control:before{
			position:static;
		}

		.video-react .video-react-big-play-button {
		    font-size: 40px;
		    width: 80px;
		    height: 80px;
		    border-radius: 50%;
		 }

		 .video-react .video-react-big-play-button.video-react-big-play-button-center {
		    margin:0px;
		    transform: translate(-50%, -50%);
		}

		.plan-images{
		/*border-radius: 6px;*/
		height: 448px;
		/*overflow: hidden;*/
		margin-bottom: 16px;
		}

		.plan-image{

			    height: 448px;
				width: 100%;
				background-position: top center;
	    		background-size: cover;
	    		background-repeat: no-repeat;
	    		background-color:lightgrey;

		}

		.plan-name-txt{
			  font-size: 13px;
			  line-height: 1.85;
			  color: var(--white);
		}

		.tags-hor{
			.plan-tags-wrap{margin-right: 33px;}
			.tag{    display: inline-block;}
		}

		.plan-hashtags{
			span{
				font-size: 12px;
  				color: #23a5ff;
			}
		}

		.plan-hashtags-title{
			margin-bottom: 0px;
		}

		.plan-historyOfRoute{
			h4{
			  font-size: 19px;
			  font-weight: bold;
			  line-height: 1.26;
			  color: var(--white);
			  margin-top: 42px;
			}

			p{
			  font-size: 14px;
			  line-height: 24px;
			  color: var(--white);
			  span{font-weight: 400;}
			}
		}


}



@media (max-width: $sm) {
	 .PlanPreview {
	   		.plan-images{
				height: 285px;

		}

		.plan-image{

				height: 285px;

		}
	}
}