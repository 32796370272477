@import './../../assets/styles/variables.scss';

.DateSelectBrowse {
	background-color:#000;
	z-index: 1;
			padding:72px 0px;



			.many-text {

  font-size: 30px;
  font-weight: bold;
margin-bottom: 26px;
  line-height: 1.33;
  color: var(--white);
}

	.DateInput.DateInput_1 {
	    max-width: calc(50% - 10px);
	}



			.DateInput.DateInput_1::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image:url('./../../assets/icons/down-chevron-w.svg');
    background-repeat:no-repeat;
    background-position: center;
        pointer-events: none;
	}

	.DateRangePicker_closeButton,.DayPickerNavigation_button{
		outline:none
	}
	.DateRangePickerInput_arrow{
		display:none;
	}
	.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    	background: #000;
    	border-color: #000;
	}
	.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover,
	.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover,
	.CalendarDay__selected_span {
	    background: rgba(0,0,0,.7);
	    border-color: rgba(0,0,0,.7);
	    color:#fff;
	}

	 .DateInput_input__focused {
	    border-bottom: 2px solid transparent;
	}

	.DateInput {
    width: 422px;
    background: #000;
	}

	.DateRangePickerInput__withBorder {

    background: #000;
	}

	.DateRangePicker_1 > div{
		    background: #000;

	}

	.DateInput_input{
	  font-size: 16px;
	  font-weight: 500;
	  line-height: 1.5;
	  color: var(--white);
	  padding-left:20px;
	  height:88px;
	  border-radius: 8px;
	 &::placeholder {
		  	  color: var(--white);
		}
	}
	
	.DateRangePicker_picker {
	    z-index: 3;
	}

	.DateRangePickerInput__withBorder {
	    border-radius: 8px;
	    border: none;
	}
	.DateInput{
		border-radius: 8px;
	}



	.main-content{

		.btn-w{
		  width: 258px;
		  height: 87px;		
		}

	}

	.date-wrap{
		width: 846px;
		max-width:100%;
	}

	div.DateInput:first-child,input.DateInput_input:first-child{
			border-radius:8px 0px 0px 8px;
			background-color:#484848;

		}

		div.DateInput:last-child, div.DateInput:last-child input.DateInput_input:first-child{
			border-radius:0px 8px 8px 0px;
			background-color:#484848;

		}



}

@media (min-width: 850px) {

	.DateSelectBrowse {

	max-width: 100%;
    
		.DateInput{
			width:422px;
		}

		div.DateInput:first-child{
			border-right: solid 1px #56585b;
			border-top-right-radius:0px;
			border-bottom-right-radius:0px;
		}

		.DateRangePicker_picker{
			    top: 84px !important;
		}
		.DateInput_fang{
			    top: 74px !important;
		}

	}
}

@media (max-width: 750px) {
	.DateSelectBrowse{

		height: 100%;
		.DateRangePicker,.DateRangePickerInput,.DateInput{
			width:100%;
		}
	}
}


@media (max-width: $sm) {
	.DateSelectBrowse{

		.main-content{
			flex-wrap:wrap;
		}

		.date-wrap{
			margin-bottom:20px;
		}

		height: 100%;
		.DateRangePicker,.DateRangePickerInput,.DateInput{
			width:100%;
		}

		.DateInput.DateInput_1 {
	   	 max-width: 50%;
		}

		.DateInput_input{
			height: 60px !important;
		}

		.btn-w{
			width: 100% !important;
			height:60px !important;
		}
	}
}

