.PlanHeatMap {
height: 100%;

	.mapboxgl-map{
		height: 100%;
		height: 100%;
	}

	.mapboxgl-ctrl-bottom-right,.mapboxgl-ctrl-bottom-left{
		display: none;
	}

	  	.map-wrap{
  			width: 100%;
  			height: 100%;
  		
  			background-color:lightgrey;
  			overflow: hidden;
  		}
}
