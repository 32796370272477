@import './../../../../assets/styles/variables.scss';

.EventSocial {

	padding-bottom: 96px;

	.social-link{
	  
	  a{
	  	font-size: 13px;
	  font-weight: 500;
	  line-height: 1.85;
	  color: var(--slate);
	  outline: none;
	  	&:hover{text-decoration: none;}
		&:visited{color: var(--slate);}
	  }

	  img{margin-right:8px;}
	}

	.spotify {
		margin-right: 17px;
		img{
			height: 16px;
		}
	}

	.youtube {
		img{
			height: 16px;
		}
	}
}

@media (max-width: $sm) {
	 .EventSocial {
		padding-bottom: 36px;
		flex-wrap:wrap;

		.social-link{
			width: 100%;
			margin-bottom:12px;
		}

	}
}