@import './../../assets/styles/variables.scss';

.Payment {
	height: 100%;
}

.coupon-dialog{

	&-content{
		padding:39px 16px 36px 16px;
		margin:0px auto;
		width: 326px;
		max-width: 100%;

		.coupon-title{

			font-size: 22px;
		  font-weight: bold;
		  line-height: 1.45;
		  text-align: center;
		  color: var(--dark-blue-grey);

		}

		.code-input{
			width: 100%;
		    max-width: 100%;
		    height: 50px;
		    margin: 20px 0;
		    padding: 13px;
		    border-radius: 4px;
		    border: solid 1px var(--very-light-blue);
		    outline: none;

		     font-size: 16px;
  			line-height: 1.5;

  			&.invalid{
  				border-color:#FF0B0B;
  				color:#FF0B0B;
  			}
  			
		}

		input::placeholder {
        	color: var(--pinkish-grey);
        }

        .btn-d,.btn-o-d{
  			height: 50px;
        }

        .btn-d{
        	width: 163px;
        }

		.btn-o-d{
        	width: 115px;
        }


	}

	.MuiDialog-paperWidthSm {
    	max-width: 426px;
    	width: 100%;
	}

	.MuiDialog-paper {
    	margin: 16px;
	}

	
}


@media (max-width: $sm) {

		
	 	.coupon-dialog{

			&-content{
				padding:21px 16px 16px 16px;

				.coupon-title{
					 font-size: 16px;
					 line-height: 2;
				}
			}
		}

	}

