@import './../../assets/styles/variables.scss';

.PlanStory {

	.image-slider {
	  width: 100%;
	  height: 356px;
	  border-radius: 4px;
	  overflow: hidden;
	  position:relative;
	  	background-color:lightgrey;

	  .plan-tag{
	  		padding:4px 8px;
		  	position:absolute;
		  	top:8px;
		  	left:8px;
		  	border-radius: 3px;
	  		background-color: var(--white);
		  font-size: 12px;
		  font-weight: bold;
		  color: var(--dark-blue-grey);  		
	  }

	  .plan-tagr{
		padding:4px 8px;
		position:absolute;
		top:8px;
		right:8px;
		border-radius: 10px;
		background-color: var(--white);
		font-size: 12px;
		font-weight: bold;
		color: var(--dark-blue-grey);  		
	}

	  .slick-dots {
	  	bottom:12px;

	  	li{
	  		width: 20px;
    		height: 4px;
    		margin:0px 1px;
	  	}

	  	li button{
	  		width: 100%;
    		height: 100%;
    		padding: 0px;
	  	}

	  	li button:before {
	  		    width: 100%;
			    height: 100%;
			    border-radius: 2px;
			    content: "";
			    background: #fff;
	  	}

	  	li.slick-active button:before {
			    opacity: 1;
	  	}

	  }

	  .plan-image{
	  	width: 100%;
	  	height: 356px;
	  	background-size:cover;
	  	background-position:top center;
	  	background-repeat:no-repeat;
	  	background-color:lightgrey;
	  }
	}

	.author-info{
		padding: 10px 0px;
		overflow: hidden;
		.name{
			font-size: 12px;
			font-weight: 500;
			line-height: 1.33;
			color: var(--dark-blue-grey);
		}
		.avatar{
			width: 20px;
			height: 20px;
			margin-right:6px;
			border-radius:50%;
			flex-shrink:0;
		}
	}

	.plan-ratings{
		.star{
		  	width: 16px;
		  	height: 16px;
		  	background-image: url("./../../assets/icons/star.svg");
		    background-repeat: no-repeat;
		    background-size: cover;
		    background-position: left;
		}
		.rating{
		  font-size: 12px;
		  font-weight: bold;
		  color: var(--dark-blue-grey);
		  margin-left: 5px;
		}
		.rate-count{
		  font-size: 12px;
		  color: var(--blue-grey);
		  margin-left: 5px;
		}
	}

	.plan-title{
		  font-size: 15px;
		  font-weight: 500;
		  line-height: 1.6;
		  color: var(--dark-blue-grey);
		  margin-top:11px;
		  margin-bottom: 14px;
	}

	.desktop-slider{
		  .slider-track {
		    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
		  }
  
		  .slick-slide {
		    
		    .plan-image {
		      width: 100%;
		      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
		      transform: scale(1.2);
		    }
		    
		    &.slick-active .plan-image {
		      transform: scale(1);
		    }

			/*
			&:hover .plan-image {
			  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
		      transform: scale(1.2);
			}

			& .plan-image {
			  transform: scale(1);
			}*/

		  }

	}

	.mobile-slider{
		display:none;
	}

}


@media (max-width: $xl) {
	 .PlanStory {
		.mobile-slider{display:block;}
		.desktop-slider{display:none;}
	}
}



