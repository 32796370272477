@import './../../../../assets/styles/variables.scss';

.PlanStoryFeature {

  .tag{
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--slate);
    margin-right:8px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 4px;

      &::before{
        content: '';
        width: 2px;
        height: 10px;
        border-radius: 1.5px;
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        background-color: var(--light-blue-grey);
      }

  }
}

@media (max-width: $sm) {
   .PlanStoryFeature {
    
  .tag{
      font-size: 10px;
      &::before{
        height: 9px;
      }
    }

  }
}

