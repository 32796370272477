.EventKeyInfo {

      	.MuiAccordionSummary-root {
		    border-radius: 8px;
  			background-color: #f9fafc;
			border-bottom:12px solid #fff;
		}

		.MuiAccordionSummary-root.Mui-expanded{
			background-color: #fff;
			border: solid 1px #e1e1e1;
			border-radius: 8px 8px 0px 0px;
			border-bottom:none;
		}



		.MuiAccordion-root.Mui-expanded {
		  margin-top: 0px;
		    
		}

		.MuiAccordionSummary-expandIcon{
			color: var(--dark-blue-grey);
		}

		.MuiAccordionSummary-content {
		  	font-size: 16px;
			font-weight: bold;
			line-height: 2;
			color: var(--dark-blue-grey);
		}

		.MuiAccordionSummary-content.Mui-expanded {
		    margin: 0px;
		}

		.MuiCollapse-container{

			background-color: #060810;

			.MuiAccordionDetails-root {
			  padding:0px 16px 16px 16px;
			  background-color: #fff;
			  border: solid 1px #e1e1e1;
			  border-radius: 0px 0px 8px 8px;
			  border-top:none;

			   font-size: 14px;

  line-height: 1.29;
  color: var(--slate);
			}

		}

		.MuiPaper-elevation1{
			box-shadow: none;
		}

		.MuiAccordion-root:before{
			content: none;
			display: none;
		}

		.MuiCollapse-wrapper{
			background-color:#fff;
		}


		.bonus-event{
			padding:24px 0px;
			border-bottom: solid 1px #e1e1e1;

			.open-hours{
				font-size: 14px;
			  line-height: 1.29;
			  color: var(--slate);
			}

			.bonus-title{
			  margin-bottom:20px;
			  font-size: 14px;
			  font-weight: 500;
			  line-height: 1.14;
			  color: var(--dark-blue-grey);
			}

			.bonus-txt{
			  font-size: 14px;
			  line-height: 1.29;
			  color: var(--slate);
			}

			.ev-img{
				flex-shrink: 0;
				width: 128px;
    			text-align: center;
			}
		}



}