@import './../../assets/styles/variables.scss';

.DesignerImageFeed {
  background-color: #f9fafc;
  padding:90px 0px 96px 0px;

  h3.sub-title{
  	  margin-bottom:40px;
  font-size: 39px;
  }

  	.images-container{
  		width:100%;
  		overflow:hidden;
  	}

	.images-wrap{
		margin-right: -17px;
	}

	.grid-img{
		width: 272px;
  		height: 272px;
  		background-color:lightgrey;
  		margin-right: 17px;
  		margin-bottom: 18px;
	}
}

@media (max-width: $xl) {
	 .DesignerImageFeed {
	 	padding:20px 0px 50px 0px;
		.grid-img{
			width: calc(33.3% - 17px);
			height:auto;
			&::after{
			  padding-top: 100%;
			  display: block;
			  content: '';
			}
		}

	}
}

@media (max-width: $lg) {
	 .DesignerImageFeed {
	 	
		.grid-img{
		    display:none;
		}
		.grid-img:nth-child(-n+9){
		    display:block;
		}

	}
}

