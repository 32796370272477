.PlanMap {
	  	.map-wrap{
  			width: 100%;
  			height: 183px;
  			border-bottom-right-radius: 5px;
    		border-bottom-left-radius: 5px;
  			background-color:lightgrey;
  			position: relative;
  			overflow: hidden;

  			.map-placement{
  				width: 100%;
  				height: 183px; 
  			}

  			a[href^="http://maps.google.com/maps"]{display:none !important}
			a[href^="https://maps.google.com/maps"]{display:none !important}

			.gmnoprint a, .gmnoprint span, .gm-style-cc {
			    display:none;
			}
			.gmnoprint div {
			    background:none !important;
			}
  		}
}