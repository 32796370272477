@import './../../assets/styles/variables.scss';

.HeaderLight {
	  box-shadow: 0 1px 0 0 var(--very-light-blue);
  background-color: var(--white);
  		padding: 19px 0px;

  .logo{  width: 200px;
	}

	.side{
		  color: var(--dark-blue-grey);

	}
	 .btn-o-d {
    width: 180px;
    height: 50px;
	}
}

@media (max-width: $sm) {
	 .HeaderLight {
	 	height: 60px;
	 	padding: 0px;
	 	  box-shadow: 0 11px 24px 0 var(--dark-blue-grey-7);
	 	  .container{height: 100%;}
 		 .logo{  width: 148px;}
		.side{
			display:none;
		}

	}
}