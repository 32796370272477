@import './../../../../assets/styles/variables.scss';

.EventCheckList {

	.checklist-item{
		  border-radius: 4px;
		  border: solid 1px #ffc233;
		  background-color: #fff7e6;
		  position: relative;
		  padding:16px 40px 16px 44px;
		  margin-bottom: 16px;
		  position:relative;

		  a{
		  	 font-size: 12px;
			  font-weight: normal;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 2;
			  letter-spacing: normal;
			  text-align: right;
			  color: var(--slate);

			  position: absolute;
			  top: 24px;
			  right: 16px;

			  img{margin-left: 10px;}

		  }

		  h5{
		      font-size: 16px;
			  font-weight: bold;
			  line-height: 2;
			  color: var(--dark-blue-grey);
			  margin-bottom:0px;
			}

		  &::before{
		  	content: '';
		  	position:absolute;
		  	transform: translate(-50%,-50%);
		  	width: 30px;
		    height: 30px;
		    left: 0px;
		    top: 50%;
		  	background-position:center;
		  	background-repeat:no-repeat;
		  	background-size:cover;
		  	background-image:url('./../../../../assets/icons/confirmation.svg');
		  }

		  p{
		  	  font-size: 13px;
			  line-height: 1.85;
			  color: var(--dark-blue-grey);
			  font-weight: 400;
		  }
	}
}

@media all and (max-width: $sm) {
	 .EventCheckList {


		.checklist-item h5 {
		    padding-left: 33px;
		}

		.checklist-item::before {
		   
		    width: 24px;
		    height: 24px;
		    left: 27px;
		    top: 32px;
		    
		}

		.checklist-item {
	    padding: 16px 15px 16px 15px;

	    a{
	    	position: static;
	    	padding-top:12px;

	    }
	
		}

	}
}