@import './../../../../assets/styles/variables.scss';

.EventAbout {

	.about-item{
		width: 100%;
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		img{
		}

		.abtxt{
			display: flex;
			align-items: center;

			max-width: 100%;
			    min-width: 0;
			
		}
		.solid{
			color: #3333;
		}
		.abimg{
			width: 30px;
			text-align: center;
			margin-right: 19px;
		}

		.desc{
		  font-size: 12px;
		  line-height: 1.5;
		  color: var(--slate);
		  font-weight: 400;
		  margin-right: 6px;
		  flex-grow: 0;
			flex-shrink: 0;
		}

		.val{
		  font-size: 14px;
		  font-weight: 500;
		  line-height: 1.14;
		  color: var(--slate);	
		  flex-grow: 1;
			flex-shrink: 1;	

		}

		.img-location{height: 24px;}
		.img-etype{height: 27px;}
		.img-clock{height: 22px;}
		.img-reception{height: 24px;}
		.img-price-tag{height: 24px;}
		.img-coin{height: 24px;}
	}
}

@media (max-width: $sm) {
	 .EventAbout {

		.about-item{
			width: 100%;
		}

	}
}