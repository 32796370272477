@import './../../assets/styles/variables.scss';

.PlanExplore {
padding-top: 32px;
	padding-bottom: 96px;

	.description{
	  font-size: 13px;
	  line-height: 2.46;
	  color: var(--slate);
	  margin-bottom: 32px;
	}

	.PlanStory{
		margin-right: 36px;
	}

	div.PlanStory:nth-child(3) {
	  margin-right: 0px;
	}

	.slick-next,.slick-prev{
		/*display: none !important;*/
	}

	.slick-prev {
    	left: -18px;
	}

	.slick-next {
    	right: 18px;
	}

	.explore-header{
		margin-bottom: 32px;
	}

	.show-all-btn{
		 font-size: 15px;
		  font-weight: 500;
		  line-height: 1.6;
/*		  text-align: right;
*/		  color: var(--dark-blue-grey);
		  cursor: pointer;
		  text-decoration: underline;
	}

			.sliderBtn{
			background: none;
    		border: none;
			outline: none;
			margin-left:16px;
			img{
				width: 32px;
			    height: 32px;
			    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
			    border-radius: 50%;
			}
		}

.explore-slider-container{
			    width: calc(100% + 36px);
   				
		}

		.explore-slider-wrap{
			width: 100%;
			overflow: hidden;
		}

		.mobile-sliderx{display: none;}


}

@media (max-width: $xl) {
	 .PlanExplore {
		
	 	.sliderBtn{display: none;}

	}
}

@media (max-width: $sm) {
	 .PlanExplore {
		.PlanStory{
			width: 152px;
		margin-right: 10px;
		}
	}
}

@media (max-width: $sm) {
	.PlanExplore {

		.desktop-sliderx{display: none;}
		.mobile-sliderx{
			display: block;

		div.PlanStory:last-child{ 
			

		}

		}
		 .PlanStory {
		 	.image-slider {
		  height: 205px;
		}
			.author-wrap{
				flex-wrap: wrap;
				.author-info{width: 100%;}
			}

			.plan-title{
			  font-size: 12px;
			}

			.plan-tag{
				font-size: 10px;
			}
		}

	}
}