@import './../../../../assets/styles/variables.scss';

.PlanRating {

	.rating{
	  font-family: Merriweather;
	  font-size: 43px;
	  font-weight: bold;
	  color: var(--white);
	  margin-right: 15px;
	}

	.desc {

	  font-size: 13px;
	  line-height: 2.46;
	  color: var(--white);
	}

	.stars-container{
		    margin-top: 15px;
	}

	.star{

	    width: 24px;
	    height: 22px;
	    background-image: url("./../../../../assets/icons/star-empty.svg");
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: left;
	    position: relative;
	    margin-right: 9px;

	  	.star-fill{
		    background-image: url("./../../../../assets/icons/star.svg");
		    top: 0px;
		    left: 0px;
		    width: 0%;
		    height: 100%;
		    position: absolute;
		    background-repeat: no-repeat;
		    background-size: cover;
		    background-position: left;
		    outline: none;
		    border: none;
	  	}

	}
}


@media (max-width: $lg) {
	 .PlanRating {

		margin-bottom:20px;

	}
}