.ShareFriendItem {
		position: relative;
		padding: 0px 15px;
		.delBtn{
			position: absolute;
    top: -20px;
    right: 10px;
    background: transparent;
    border: none;
    outline: none;
		}

.thanks {

  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
  color: var(--dark-blue-grey);
  margin-bottom: 10px;
}

.look {

  font-size: 13px;

  line-height: 1.85;
  text-align: center;
  color: var(--slate);
}

		.form-wrap{
		padding-bottom:40px;
		 
		  border-bottom: solid 1px var(--very-light-pink);

		.pay-w{
			margin-bottom: 28px;
			h4{
				font-size: 30px;
			  font-weight: bold;
			  line-height: 1.33;
			  color: var(--slate);
			}
			img{
				margin-left:12px;
			}
		}
		.terms{
		  font-size: 13px;
		  line-height: 1.85;
		    color: var(--slate);

		  margin-top: 30px;

		  a,a:hover,a:visited{
				  color: var(--green);
				  margin-left: 5px;
				  margin-right: 5px;
			}

		}


		.form-inline{
			.MuiFormControl-root {
 
			    width: 50%;
			}
		}
	}
}