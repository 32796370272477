@import './../../../../assets/styles/variables.scss';

.DesignerCities {
	h4{
		margin-bottom: 10px;
	}

	.MuiTab-wrapper{
		font-family: 'Airbnb Cereal' !important;
	}

	.MuiTabs-root {

	    min-height: auto;
	    border-bottom: solid 1px #b3b3b3;

	}

	.MuiTab-root {
	    min-width: auto;
	    padding: 0px 0px 6px 0px;
	    text-transform: none;
	    outline: none;
	    margin-right: 24px;

	}

	.cityDesc{
		padding:0px 0px 9px 0px;
		p{
		  font-size: 13px;
		  line-height: 2.15;
		  color: var(--slate);
		}
	}

	.MuiTab-wrapper{
	 font-size: 15px;
	  font-weight: 500;
	  line-height: 1.6;
	  color: var(--dark-blue-grey);
	}
	.MuiTabs-indicator{
		height: 4px;
  		background-color: var(--dark-blue-grey);
	}

	.MuiBox-root{
		padding:27px 0px 82px 0px;
	}

	.desc{
		font-size: 13px;
  		line-height: 2.15;
  		color: var(--slate);
  		margin-bottom:16px;
	}

	.images-container{
		width:100%;
		overflow:hidden;
	}

	.images-wrap{
		margin-right: -20px;
	}

	.grid-img{
		width: 169px;
	 	height: 150px;

  		background-color:lightgrey;
  		margin-right: 20px;
  		margin-bottom: 20px;
  		flex-shrink:0;
	}
	div.grid-img:last-child{
		
	}

		.show-more-wrap{
		padding-top:44px;
		text-align:center;

		.sm-btn{
			width: 200px;
  			height: 50px;
		}
	}

}
@media (max-width: $lg) {
 .DesignerCities {
		.MuiBox-root {
		    padding: 16px 0px 29px 0px;
		}
	}
}

@media (max-width: $xl) {
	 .DesignerCities {

	 	.images-container {
		    position: relative;
		    width: calc(100% + 15px);
		}

	 	.images-wrap{
			margin-right: -20px;
		    flex-wrap: nowrap !important;
		    overflow-x: scroll;
		}

		.grid-img {
			width: 156px;
		 	 height: 144px;
	 		 margin-right: 16px;
	 		 margin-bottom: 16px;
		}
		.show-more-wrap{display: none;}

	}
}