@import './../../../../assets/styles/variables.scss';

  .mapboxgl-ctrl-bottom-right,.mapboxgl-ctrl-bottom-left{
    display: none;
  }

.EventLocation {
	padding-bottom:91px;


	.MuiAccordionSummary-root{
		height: auto;
		min-height: auto;
	}

	.MuiAccordionSummary-root{
		margin: 0px;
		padding:0px;
		height: auto;
		min-height: auto;
	}

	.MuiAccordion-rounded:last-child,.MuiAccordion-rounded{
		border:none;
	}

	.MuiAccordionDetails-root {

    padding: 0px;
	}

	.MuiAccordion-root {
    	border:none !important;
    	box-shadow: none !important;
	}


	.MuiAccordion-root:before {
   
   		background-color: transparent;
	}




	.location-container{
		border-radius: 5px;
  		border: solid 1px #dfdfdf;
  		.location-info{
  			padding:0px;
  		}

  		.hours{
  			    font-weight: 500;

  		}

  		.openHours-cont{
  			    border-top: solid 1px #dfdfdf;
    		padding: 0px 16px;

    		.MuiIconButton-label{
    			color: var(--slate);
    			font-size: 36px;
    		}

    		.MuiIconButton-root{
    			    padding: 6px;
    		}
  		}

  		.map-wrap{
  			width: 100%;
  			height: 126px;
  			border-radius: 5px;
  			background-color:lightgrey;
  			position: relative;
  			overflow: hidden;

  			.map-placement{
  				width: 100%;
  				height: 126px; 
  			}

  			a[href^="http://maps.google.com/maps"]{display:none !important}
			a[href^="https://maps.google.com/maps"]{display:none !important}

			.gmnoprint a, .gmnoprint span, .gm-style-cc {
			    display:none;
			}
			.gmnoprint div {
			    background:none !important;
			}

			&::after{
				content: '';
				position:absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom:0;
				pointer-events: none !important;
				cursor: default;
				z-index: 1;
			}

  		}

  		.info-wrap{
  			padding-left:16px;
  			.name{
			  font-size: 17px;
			  font-weight: 500;
			  line-height: 1.41;
			  color: var(--dark-blue-grey);
			  margin-bottom: 1px;
  			}
  			.address{
			  font-size: 12px;
			  font-weight: 400;
			  line-height: 2;
			  color: var(--slate);
			  margin-bottom: 25px;
			  			  text-decoration: underline;

  			}
  			.hours{
			  font-size: 12px;
			  font-weight: 400;
			  line-height: 1.5;
			  color: var(--slate);
			  margin-bottom: 7px;
  			}
  			.hours-val{
			  font-size: 14px;
			  font-weight: 500;
			  line-height: 1.14;
			  color: var(--slate);  				
  			}
  		}
	}

	.marker-wrap{
		position:relative;
		text-align: center;
	}

	.marker-txt{
    position: relative;
    border-radius: 4px;
    background-color: #1b2d41;
    color: #fff;
    padding: 6px 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    font-size: 12px;
    font-weight: 500;
    transform: translate(-50%,0%);
    left: 50%;
    top: -10px;

		&::after{
			    content: '';
			    width: 0;
			    height: 0;
			    border-left: 10px solid transparent;
			    border-right: 10px solid transparent;
			    border-top: 10px solid #1b2d41;
			    position: absolute;
			    top: 100%;
			    left: 50%;
			    transform: translate(-50%,-50%);
		}
	}

	.marker-icon{
		width: 30px;
	}
}

@media (max-width: $md) {
	 .EventLocation {

		.location-container{
			.map-wrap{
				width: 100%;
	  			height: 86px;
	  			flex-shrink: 0;
			}
		}

	}
}