@import './../../assets/styles/variables.scss';

.PlanDetail {
	background: #000;
	padding: 43px 0px 77px 0px;

		.tags-title {
		  height: 24px;
		  font-size: 13px;
		  line-height: 1.85;
		  color: var(--white);
		  margin-bottom: 10px;
		}

		.summary-title{

		  font-size: 15px;
		  font-weight: 500;
		  line-height: 1.6;
		  color: var(--white);
		  margin-bottom: 15px;
		}

		.planBtns{
			margin-bottom: 35px;
		}

		.planBtn{
		  height: 56px;
		  border-radius: 4px;
		  background-color: #13151d;
		  padding-left:16px;

		  font-size: 13px;
		  line-height: 1.85;
		  color: var(--white);
		  outline:none !important;
		  width:100%;
		  border:none;
		  text-align: left;
		  margin-bottom: 12px;
		  img{
		  	margin-right: 15px;
		  }
		}

		button.planBtn:hover{
		  filter: brightness(1.5);
		}

		.summary-box{
			height: 342px;
			position: relative;

			&::after {
			    background-image: linear-gradient(to bottom, var(--black-0), rgba(0, 0, 0, 0.73) 69%);
			    content: '';
			    height: 30px;
			    bottom: 0px;
			    width: 100%;
			    left: 0px;
			    position: absolute;
			    /* z-index: 1; */
			}
		}

}


@media all and (max-width: $lg) {
	 .PlanDetail {

		.PlanPreview{
			width: 100%;
			padding-right: 0px;
		}

		.planBtns{
			margin-top: 38px;
		}

	}
}

@media (max-width: $sm) {
	 .PlanDetail {
		
	 	padding-bottom:36px;

	}
}
