// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Optional
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/spinners";

@import './assets/font/style.css';
@import './assets/styles/variables.scss';

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-perfect-scrollbar/dist/css/styles.css';

:root {
  --slate: #3c4857;
  --white: #ffffff;
  --dark-blue-grey-7: rgba(27, 45, 65, 0.07);
  --very-light-blue: #e5e9f2;
  --black-0: rgba(0, 0, 0, 0);
  --black-76: rgba(0, 0, 0, 0.76);
  --blue-grey: #8292a5;
  --light-blue-grey: #c0ccda;
  --pale-grey: #f6f6f7;
  --light-periwinkle: #d3d3df;
  --yellow-orange: #ffb300;
  --pale: #fff5dc;
  --red-pink: #f53158;
  --green: #1eab31;
  --very-light-pink: #efefef;
  --pinkish-grey: #b2b0b0;
  --dark-blue-grey-13: rgba(27, 45, 65, 0.13);
  --seafoam-blue: #7ebed4;
  --green-9: rgba(30, 171, 49, 0.09);
  --green-28: rgba(30, 171, 49, 0.28);
  --black-70: rgba(0, 0, 0, 0.7);
  --dark-blue-grey: #1b2d41;
}

html,body {
    font-family: 'Airbnb Cereal' !important;
    margin: 0;
    padding: 0;
    background: var(--white);
    height: 100%;
}

ElementsApp input{
    font-family: 'Airbnb Cereal' !important;
}

.App,#root {
  height: 100%;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bgImg{
    background-position:top center;
    background-repeat:no-repeat;
    background-size:cover;
    background-color:lightgrey;
}

.container{
  max-width: 1170px;
}

.MuiTouchRipple-root {display: none}


p{
  margin: 0px;
}

a,a:hover{
  outline:none;
  text-decoration:none;
}

.btn{
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  border-radius: 4px;
  outline:none !important;
  max-width:100%;
  border:none;

}

.btn-w{
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--dark-blue-grey);
  /*padding:13px 8px;*/
  border-radius: 4px;
  background-color: var(--white);
  border:none;
  outline:none !important;
  max-width:100%;
}

.btn-d{
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: var(--white);
  border-radius: 4px;
  background-color: var(--dark-blue-grey);
  border:none;
  outline:none !important;
  max-width:100%;
}


.btn-o-w,.btn-o-d{
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: var(--white);
  border-radius: 4px;
  background-color: transparent;
  border:1px solid #fff;
  outline:none !important;
  max-width:100%;
}

.btn-o-d{

  color: var(--dark-blue-grey);
  border: solid 1px var(--very-light-blue);

}

button.btn-w:hover{
  filter: brightness(0.9);
}

button.btn-d:hover{
  filter: brightness(1.5);
}

button.btn-o-w:hover{
  background-color: rgba(255,255,255,.15);
}

button.btn-o-d:hover{
  /*filter: brightness(0.9);*/
  background-color: rgba(0,0,0,.1);
}


.section-title{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  color: var(--slate);
}

.sub-title{
  font-size: 19px;
  font-weight: bold;
  line-height: 1.26;
  color: var(--dark-blue-grey);
  margin-bottom: 24px;
}

.slick-prev, .slick-next{
  width:50px;
  height: 50px;
    z-index: 1;
}

.slick-prev {
    left: 16px;
}

.slick-next {
    right: 16px;
}

.slick-next:before,.slick-prev:before{
  content: '';
  width:32px;
  height: 32px;
  box-shadow:0px 2px 4px rgba(0,0,0,0.3);
  background-color:#fff;
  border-radius:50%;
        background-position:center;
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
    opacity: 1;
    margin:0px auto;

}
.slick-next:before{
  background-image:url("./assets/icons/slider-right.svg");
}

.slick-prev:before{
  background-image:url("./assets/icons/slider-left.svg");
}

.avatar{
  background-size:cover;
  background-repeat:no-repeat;
  background-position: top center;
}

.not-valid-error {
    font-size: 11px;
    color: #FF0B0B;
    padding: 5px 0px;
    text-align: center;
}

/*.modal-center{
   display: flex;
   align-items: center;
   justify-content: center;
}*/

/*.modal-center {

    max-width: 100%;
    transform: translate(-50%, -50%);
    left: 50% !important;
    top: 50% !important;
    right: auto !important;
    bottom: auto !important;
    z-index: auto;
}*/

.modal-center{
  z-index: 1;
}

@media (max-width: $sm) {
   .sub-title {
    font-size: 24px;
  }
}

@media (max-width: $lg) {
 
  .section-title{
  font-size: 24px;
 line-height: 1.67;
width: 100%;
}

}


