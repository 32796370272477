.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover,
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover,
.CalendarDay__selected_span {
      background: rgba(0,0,0,.7) !important;
      border-color: rgba(0,0,0,.7) !important;
      color:#fff !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
	background: #000 !important;
	border-color: #000 !important;
}

	button.mpCLose {
	    width: 50px;
	    position: absolute;
	    z-index: 1;
	    background: #fff;
	    right: 10px;
	    top: 10px;
	    border-radius: 0;
	    border: 1px solid grey;
	    height: 50px;
	}

	.DateRangePicker_picker__fullScreenPortal,.DateRangePicker_picker{
		z-index: 4 !important;
	}

