
.AltEventDetail {
	position:relative;
	 .main{
			width: 100%;
			padding-top: 10px;
	}

	.demo-notice{    display: none !important;}
	h2{display: none;}

	.event-main-image{
			height: 516px;
			background-position:center;
			background-repeat:no-repeat;
			background-size:cover;
			background-color:lightgrey;
			    width: 100%;
	}

	.EventSocial {
    padding-bottom: 0px;
	}
	.PlanActions{
		position:sticky;
		/*position:relative;*/
		width: 100%;
		left:0px;
		bottom:0px;
		box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.17);
		.full-access{
			margin:0px;
			border-radius: 0px;
			background-color:#fff;

			p{  color: var(--dark-blue-grey); margin-top: 0px;}

			.btn-w {
    				background-color: var(--dark-blue-grey);
    				color: #fff;
				}

			.price{
				font-size: 26px;
			  font-weight: bold;
			  line-height: 1.23;
			  margin-top:4px;
			}
		}
	}

	.EventCheckList{
		padding-top:40px;
	}

	.EventLocation {
    padding-bottom: 40px;
	}


}

div.AltEventDetail > div.container{
	padding: 0px;
}


@media (max-width: 750px) {
	.AltEventDetail {
	
		.event-main-image{
				height: 331px;
		}
	}
	
}