@import './../../assets/styles/variables.scss';

.BrowsePlans {

	.stories-container{
		width:100%;
		overflow:hidden;
	}

	.stories-wrap{
		margin-right: -36px;
	}

	.PlanStory{
		margin-right: 36px;
		width: 258px;
		margin-bottom: 50px;
	}

.change-action{
	margin-top: 24px;
	img{
  width: 32px;
  height: 32px;
  margin-right: 28px;
  margin-bottom:74px;
	}
	p{
		 font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  color: var(--dark-blue-grey);
	}
}

.change-notice{
	margin:50px 0px;
	.title{
 font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  color: var(--slate);
	}
	.txt{
 font-size: 13px;
  line-height: 2.46;
  color: var(--slate);
	}
}

.free-wrap{
		margin-bottom:50px;
border-radius: 4px;
  border: solid 1px var(--light-periwinkle);
  padding: 24px 32px;
	.title{
 font-size: 16px;
  font-weight: bold;

  line-height: 1.44;
  color: var(--dark-blue-grey);
	}
	.txt{
 font-size: 13px;
  line-height: 1.85;
/*  text-align: center;
*/  color: var(--slate);
	}
}

.btn-d{
  width: 302px;
  height: 50px;

}
}


@media (max-width: $lg) {
	 .BrowsePlans {

	 	.stories-container{
			overflow-x:scroll;
		}

		.stories-wrap{
			flex-wrap:nowrap!important;
		}

	}
}

@media (max-width: $lg) {
	 .BrowsePlans {

	 	.stories-container{
			overflow-x:scroll;
		}

		.stories-wrap{
			flex-wrap:nowrap!important;
		}

	}
}

@media (max-width: $md) {
	 .BrowsePlans {

	 	.free-wrap{
	 		display:block !important;

	 		.btn-d{width:100%;}
	 		.txt{margin-bottom:5px;}
	 	}

	 	.change-notice{
			.title{
		 		font-size: 22px;
			}
			.txt{
				font-size:12px;
				line-height:2;
			}
		}

	}
}

@media (max-width: $sm) {
	 .BrowsePlans {
	 	.change-notice{
	 		margin:16px 0px;
	 	}

	 	.PlanStory {
		width: 244px;
		    margin-right: 12px;
	    margin-bottom: 16px;
		}
	 	
	}
}