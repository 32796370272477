@import './../../../../assets/styles/variables.scss';

.DesignerCountries {
	padding-bottom:62px;
	h4{
		margin-bottom: 15px;
	}

	.show-more-wrap{
		padding-top:44px;
		text-align:center;

		.sm-btn{
			width: 200px;
  			height: 50px;
		}
	}

	.country {
	  width: 169px;
	  height: 150px;
	  border-radius: 8px;
	  	background-image: linear-gradient(to bottom, var(--black-0), rgba(0, 0, 0, 0.5));
	  	background-color:lightgrey;
	  	margin-right: 20px;
	  	margin-bottom: 20px;
	  	position:relative;
	  	flex-shrink:0;
	  	.name{
	  		background-image:url('./../../../../assets/icons/map-pin-w.svg');
	  		background-repeat:no-repeat;
	  		background-size:15px 18px;
	  		background-position:0px center;
	  		padding-left: 22px;
			font-size: 17px;
			font-weight: 500;
			color: var(--white);
			position:absolute;
			left:10px;
			bottom:10px;

	  	}
	}
	.countries-container{
  		width:100%;
  		overflow:hidden;
  	}
	.countries-wrap{
		margin-right: -20px;
	}

}






@media (max-width: $xl) {
	.DesignerCountries {

		.countries-container {
		    position: relative;
		    width: calc(100% + 15px);
		}

	 	.countries-wrap {
		    margin-right: -20px;
		    flex-wrap: nowrap !important;
		    overflow-x: scroll;
		}
		.country {
			width: 156px;
		 	 height: 144px;
	 		 margin-right: 16px;
		}

		.show-more-wrap{display: none;}
	}
}