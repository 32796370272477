@import './../../assets/styles/variables.scss';

.PlantTreeInfo {
 background-color: #a8f7c2;
 padding:96px 0px;

img{
  width: 126px;
  height: 136px;
  margin-right: 83px;
}

.btn-d{
 width: 239px;
  height: 56px;
}


p{
  font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  color: var(--slate);
/*  margin-bottom: 36px;
*/}


}

@media (max-width: $sm) {
	 .PlantTreeInfo {

		padding:36px 0px;
		
		.img{
			text-align: left;
		    width: 100%;
		    margin-bottom: 24px;
		}

		img{
		  width: 107px;
		  margin-right: 0px;
		}

		.btn-d{
		 width:100%;
		}

		p{
		  font-size: 24px;
  		  line-height: 1.67;	
		}

		.tree-container{
			flex-wrap:wrap;
		}

	}
}