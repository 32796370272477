.Review {
  padding: 20px;
   border-radius: 4px;
  border: solid 1px #e1e1e1;
  min-height: 150px;


  .quote{
  font-family: Merriweather;
  font-size: 17px;
  line-height: 1.88;
  color: var(--slate);
  }

  .more{
    font-weight: 900;
    cursor: pointer;
  }

	  .author{
	  	font-size: 16px;
	  	font-weight: bold;
		line-height: 2;
		color: var(--dark-blue-grey);
    margin-top:14px;
	  }

}