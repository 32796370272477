@import './../../../../assets/styles/variables.scss';

.PlanActions {

	.full-access{
	  border-radius: 4px;
	  box-shadow: 0 18px 39px 0 var(--dark-blue-grey-7);
	  background-color: #1b1b1b;
	  padding: 25px;
	  margin-bottom: 42px;
	}


	p {
	  font-size: 13px;
	 /* line-height: 1.85;*/
	  color: var(--white);
	}

	.price{
	  font-size: 22px;
	  font-weight: bold;
	  line-height: 1.45;
	  color: var(--white);
	}

	.price-full{
		font-weight: 300;
  		line-height: 1.6;
  		font-size: 15px;
  		color: var(--pinkish-grey);
  		text-decoration: line-through;
  		margin-right:6px;
  		padding-right: 0px !important;
	}

	.btn-w{

	  width: 139px;
	  height: 50px;
	  outline: none;
	  border:none;
	}

	.dis{
		background-color: #4c4c4c !important;
		color: rgb(29, 29, 29) !important;
	}
	
	button.dis:hover{
		filter: none !important;
	  }


}

@media (max-width: $lg) {
	 .PlanActions {
	    /* display: none; */
	    position: fixed;
	    bottom: 0px;
	    width: 100%;
	    z-index: 2;
	        left: 0px;
	     .full-access{
	    margin-bottom: 0px;
	        border-radius: 0px;
		}

		p{font-size:12px;padding-right: 10px;}


	}
}