.BreadCrumb {

 height: 60px;
  box-shadow: 0 1px 0 0 #161616;
  background-color: #1b1b1b;
  padding-top: 15px;

  .MuiTypography-colorTextSecondary,p,a,a:hover,a:visited{
	 font-size: 13px;
	  line-height: 2.46;
	  color: var(--white);
	  text-decoration: none;
  }

  .MuiBreadcrumbs-ol {
  	flex-wrap: nowrap;
  }

	li.MuiBreadcrumbs-li {
	    min-width: 0;
	    flex-shrink: 0;

	    p{
	    	overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
	    }
	}

	li.MuiBreadcrumbs-li:last-child {
	    flex-shrink: 1;
	}

}


