@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 100;
    src: local('Airbnb Cereal Light'), url('AirbnbCerealLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 200;
    src: local('Airbnb Cereal Light'), url('AirbnbCerealLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 300;
    src: local('Airbnb Cereal Light'), url('AirbnbCerealLight.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: normal;
    src: local('Airbnb Cereal Book'), url('AirbnbCerealBook.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 500;
    src: local('Airbnb Cereal Medium'), url('AirbnbCerealMedium.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 600;
    src: local('Airbnb Cereal Medium'), url('AirbnbCerealMedium.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: bold;
    src: local('Airbnb Cereal Bold'), url('AirbnbCerealBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 800;
    src: local('Airbnb Cereal Extra Bold'), url('AirbnbCerealExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Airbnb Cereal';
    font-weight: 900;
    src: local('Airbnb Cereal Black'), url('AirbnbCerealBlack.ttf') format('truetype');
}
@font-face {
    font-family: 'Merriweather';
    font-weight: normal;
    src: local('Merriweather-Regular'), url('Merriweather-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Merriweather';
    font-weight: bold;
    src: local('Merriweather-Bold'), url('Merriweather-Bold.ttf') format('truetype');
}