@import './../../../../assets/styles/variables.scss';

.PlanProfile {
	margin-bottom: 42px;

	.avatar{
		background-color: #1b1b1b;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		  background-position:top center;

	}

	.info{
		padding-left: 25px;
		overflow:hidden;
		.name{
		  font-size: 20px;
		  font-weight: 500;
/*		  line-height: 0.8;
*/		  color: var(--white);
		}
		.occupation{
		  font-size: 13px;
/*		  line-height: 2.46;
*/		  color: var(--white);
		}
		.trips{
		  font-size: 13px;
/*		  line-height: 2.46;
*/		  color: #b0b0b0;
		}
		.local{
		  font-size: 13px;
/*		  line-height: 1.85;
*/		  color: var(--white);
		}
	}

	.liveicon{
		filter: brightness(20);
		width: 10px;
    margin-right: 5px;
    position: relative;
    bottom: 1px;
	}

}



@media (max-width: $sm) {
	 .PlanProfile {

		.avatar{
			width: 66px;
			height: 66px;
		}

		.info{
			padding-left: 15px;
		}

	}
}