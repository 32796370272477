@import './../../../../assets/styles/variables.scss';

.DesignerOther {
	position:relative;
	overflow: hidden;

	.slick-track{
		margin-left: 0;
	}
.do-title{
 font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--dark-blue-grey);
}

h4{margin-bottom: 20px;}

	&::after {
	    content: '';
	    position: absolute;
	    right: -135px;
    	bottom: 0px;
    	height: calc(100% - 59px);
	    width: 236px;
	    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), var(--white) 50%);
	}

  .PlanStory{
  	margin-right:20px;
  	width: 258px;

  }

	.sliderBtn{
			background: none;
    		border: none;
			outline: none;
			margin-left:16px;
			img{
				width: 32px;
			    height: 32px;
			    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
			    border-radius: 50%;
			}
		}

}

@media (max-width: $xl) {
	 .DesignerOther {
		
	 	.sliderBtn{display: none;}

	}
}


@media (max-width: 440px) {
	 .DesignerOther {
		  .PlanStory{
		  	margin-right:0px;
		  	width: 100%;
		  	width: 152px;
		  }

		  .slick-slide{
		  	width:100%;
		  }
	}
}


@media (max-width: $sm) {
	.DesignerOther {

		 .PlanStory {
		 	.image-slider {
		  height: 205px;
		}
			.author-wrap{
				flex-wrap: wrap;
				.author-info{width: 100%;}
			}

			.plan-title{
			  font-size: 12px;
			}

			.plan-tag{
				font-size: 10px;
			}
		}

	}
}