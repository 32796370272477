@import './../../assets/styles/variables.scss';

.Plan {
	height: 100%;
	background-color:#fff;
	button.MuiButtonBase-root.MuiIconButton-root.mpCLose.MuiIconButton-colorInherit.MuiIconButton-edgeStart {
	    width: 50px;
	    position: absolute;
	    z-index: 1;
	    background: #fff;
	    right: 10px;
	    top: 10px;
	    border-radius: 0;
	    border: 1px solid grey;
	    height: 50px;
	}

	.mobile-actions-area{
		display: none;
		background-color:#000;
	}

	/*	element.style {
	    position: absolute;
	    width: 50px;
	    height: 50px;
	    z-index: 1;
	    background: #fff;
	    border-radius: 0px;
	    border: 1px solid #000;
	    top: 10px;
	    right: 10px;
		}
	*/


}


@media (max-width: $lg) {
	 .Plan {

	 	.mobile-actions-area{
	 		display: block;
	 		height: 100px;
	 	}

	 		.slick-next:before, .slick-prev:before {
		    width: 24px;
		    height: 24px;
		   }

		   .slick-prev, .slick-next {
			    width: 40px;
			    height: 40px;
			    z-index: 1;
			}

			.slick-prev {
			    left: -15px;
			}

			.slick-next {
			    right: -15px;
			}

	}
}