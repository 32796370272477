@import './../../assets/styles/variables.scss';

.PlanReviews {
	padding-top: 96px;
	padding-bottom: 64px;
	position: relative;
	z-index: 1;
	  background-color: #f9fafc;


.sorted {

  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: right;
  color: var(--dark-blue-grey);
}

		.sliderBtn{
			background: none;
    		border: none;
			outline: none;
			margin-left:16px;
			img{
				width: 32px;
			    height: 32px;
			    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
			    border-radius: 50%;
			}
		}


/*.Review{width: calc(100% );}
*/
	.slider-container{
		height: 245px;/*review on top*/
		width: calc(100% + 18px );
	    margin-left: -18px;
	}

	.slick-slider {
		height: 100%;/*review on top*/
	}
	.Review{
		margin-right: 18px;
		margin-left: 18px;
		background: #fff;
		width: calc(100% - 18px);
	}

	div.Review:nth-child(3) {
	  margin-right: 0px;
	}

	.revtitle{
		margin-bottom: 32px;
	}

	.slick-prev {
    	left: -10px;
	}

	.slick-next {
    	    right: -24px;
	}

	.slick-dots {
    	bottom: -40px;
	}

	.slick-dots li button {
		color: transparent;
		padding: 0px;
	}

	.slick-dots li button:before {
		border-radius: 50%;
	    border: 2px solid  var(--dark-blue-grey);
	    opacity: 1;
	    color: transparent;

	}

	.slick-dots {
		li.slick-active  button {
		    background: var(--dark-blue-grey);
		    border-radius: 50%;
		}
	}

}


@media (max-width: $xl) {
	 .PlanReviews {
		.Review{
			
		}

		.slider-container{
			height: auto;/*no review on top*/
		}

	}
}


@media (max-width: $xl) {
	 .PlanReviews {
		
	 	.sliderBtn{display: none;}
		.sorted{
			font-size:13px;
		}

	}
}

@media (max-width: $sm) {
	 .PlanReviews {
		
	 	padding-top:48px;

	}
}




