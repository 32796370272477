@import './../../assets/styles/variables.scss';
.modal-open{
	position: relative;
    overflow: hidden;
}
.EventDetail {
	background-color:#f9f9f9;
	padding: 96px 0px;

	.bonus-view-backdrop{
		    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: rgba(0,0,0,0.7);
    overflow: hidden;
    z-index: 2;
	}
	.sliderBtn{
		background: none;
		border: none;
		outline: none;
		margin-left:16px;
		img{
			width: 32px;
			height: 32px;
			box-shadow: 0 4px 8px rgba(0,0,0,0.1);
			border-radius: 50%;
		}
	}

		.new-plan-title{

  font-size: 18px;
  font-weight: 500;
 
  line-height: 2.22;
  color: var(--slate);
    padding: 0px 16px;
    		  margin-bottom: 25px;

	}

	.media-link{

		margin:15px 0px 10px 0px;

			  a,a:hover,a:visited{
				  font-size: 13px;
					  line-height: 1.85;
					  color: #33bbff;
					  text-decoration: none;
			}
	
	}

	.new-desc{
		 font-size: 14px;
  		line-height: 1.71;
  		color: var(--slate);
  			padding-bottom:50px;
  			position: relative;
    		top: -7px;


	}
	.bonus-shelf{

		position:fixed;
		top:0px;
		right: 0px;
		height:100%;
		width:705px;
		max-width: 100%;
		background-color:#fff;
		overflow-y: scroll;
		display: block;
		-webkit-overflow-scrolling: touch;
		z-index: 2;
		padding-bottom:0px;

				div{max-width: 100%;}

				.shelf-title{
					background-color:#fff;
					padding:36px 33px 19px 33px;
					  font-size: 20px;
					  font-weight: bold;
					  font-stretch: normal;
					  font-style: normal;
					  line-height: 1.9;
					  letter-spacing: normal;
					  text-align: center;
					  color: var(--slate);
					  position:relative;
					  .btn{
					  	position: absolute;
					    right: 33px;
					    background: #fff;
					    border: none;
					    top: 27px;
					  }
				}

	}


	.bonus-alt-container{
		margin-bottom: 56px;

		width: 100%;
		
		.slick-track
		{
		    display: flex !important;
		        margin: 0px;
		}

		.slick-slide
		{
		    height: inherit !important;
		}

		div.slick-slide.slick-active>div {
		    height: 100%;
		    min-height: 100%;
		}

		.ba-wrap{
			height: 100%;
			padding-top:20px;
				    min-height: 100%;
				    margin-right: 7px;

			.ba-event{
				    min-height: 100%;
    position: relative;
    padding-bottom: 130px;

    position:relative;


    			.type-txt{
    				position:absolute;
    				left:0px;
    				top:10px;
    				 font-size: 14px;
				  font-weight: bold;
				  line-height: 1.71;
				  color: var(--dark-blue-grey);
				  padding-left:8px;
				  padding-right:8px;
				   /* width: 129px;*/
				  height: 24px;
				  border-top-right-radius: 3px;
				    border-bottom-right-radius: 3px;

				  background-color: #ffe7e7;

				  &.bns{  background-color: #c8feaa;}
    			}
			}


			.ev-cnt{
				padding:20px;
			}
			.bonus-title{  
							font-size: 14px;
			  font-weight: bold;
			  font-stretch: normal;
			  font-style: normal;
			  line-height: 1.71;
			  letter-spacing: normal;
			  color: var(--dark-blue-grey);
				}

			.bonus-txt{
				 font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: var(--dark-blue-grey);
			}

		.bonus-tag{
			  font-size: 12px;
			  font-weight: 500;
			  color: var(--slate);
			  border-radius: 4px;
		  	  border: solid 1px var(--slate);
		  	  padding: 2px 5px;
		  	  /*padding-top:3px;*/
		  	  margin-top: 17px;
		  	  /*text-transform: uppercase;*/
		  	  display: inline-block;
		  	  position: absolute;
				left: 20px;
			    bottom: 90px;
				right: 20px;
			}

			.btn-w{
    		

    			    border: solid 1px var(--very-light-blue);
    border-radius: 4px;
    width: calc(100% - 40px);
    height: 50px;
    margin-top: 20px;
    position: absolute;
    left: 20px;
    bottom: 20px;

			}
			
			.ev-img{
				height: 147px;
				background-position:center;
			    background-repeat:no-repeat;
			    background-size:cover;
			    background-color:lightgrey;
			}

			.ba-event{
				  width: 313px;
				  /*height: 385px;*/
				  border-radius: 8px;
				  border: solid 1px #e1e1e1;
				  overflow: hidden;
			}
		}
	}


	.PlanMapBox .map-wrap{
		height: 183px;
	}

	.main{
		    background: var(--white);
		    /*width: 712px;*/
		    width: calc(100% - 428px);
		    padding:41px 36px 36px 36px;
		    img{/*margin-right:36px;*/}
	}

	.sidebar{
		    
		    width: 386px;

		    .c-wrap{
		    	padding:41px 36px 0px 36px;
		    	background: var(--white);
		    	margin-bottom: 36px;
		    }

	}

	.des-title{
	  font-size: 30px;
	  font-weight: bold;
	  line-height: 1.33;
	  color: var(--slate);
	  margin-bottom: 15px;
	}

	.event-plan-container {
		background: var(--white);
		padding: 36px 20px 24px 20px;
		h2{
		  font-size: 30px;
		  font-weight: bold;
		  line-height: 1.33;
		  color: var(--slate);
		  padding:0px 16px;
		}
	}

	.full-access{
		background-color:#000;
		padding:24px;
		text-align: center;
		margin-top:22px;
		p{
			margin-top: 10px;
			 color: var(--white);
		}	

		.btn{
			margin-top: 27px;
			  width: 266px;
  			height: 60px;
		}
		.dis{
			background-color: #4c4c4c !important;
			color: rgb(29, 29, 29) !important;
		}
		
		button.dis:hover{
			filter: none !important;
		  }
	}

	.join-event{
		padding:36px 32px;
		background:var(--white);
		margin-top: 36px;
		p{
			  font-size: 28px;
			  line-height: 1.43;
			  text-align: center;
			  color: var(--slate);
			  span{
			  	text-decoration: underline;
			  }
		}
	}

}

@media all and (max-width: 1024px) {
	 .EventDetail {
		.main{
			width: 100%;
			.demo-notice{
				justify-content:flex-start !important;
			}
		}

		.sidebar{
		    width: 100%;
		    background-color:#fff;
		}
	}
}

@media (max-width: 750px) {
	.EventDetail {
		.explore-header{
				display: none !important;
		}
	}
	
}


@media all and (max-width: $sm) {
	 .EventDetail {
	 	padding: 0px 0px;

	 	&>div.container{
	 		padding:0px;
	 	}
		.main{padding:36px 15px 36px 15px;}

		.sidebar .c-wrap {
		    padding: 41px 15px 36px 15px !important;
		}


		.event-plan-container {
		    padding: 36px 32px 24px 32px !important;
		    margin-left:15px;
		    margin-right:15px;
		     border-radius: 4px;
  			border: solid 1px #e1e1e1;
		}

		.join-event {
		    padding: 36px 15px !important;
		}

		.checklist-item h5 {
		    padding-left: 33px;
		}

		.checklist-item::before {
		   
		    width: 24px;
		    height: 24px;
		    left: 27px;
		    top: 64px;
		    
		}

		.checklist-item {
	    padding: 49px 15px 40px 15px;
	
		}
		.full-access{padding: 22px;}
		.full-access p{
			font-size: 13px;
		  font-weight: 500;
		  font-stretch: normal;
		  font-style: normal;
		  line-height: 1.71;
		}

		.event-plan-wrap .title{
			padding:0px;
		}

	}


/*	.bonus-shelf{
		background-color:green;

		position:fixed;
		top:0px;
		right: 0px;
		height:100%;
		width:450px;
		background-color:gray;
		overflow: scroll;
		z-index: 1;

				div{max-width: 100%;}

	}*/

}





